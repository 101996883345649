.product
    +make-row(0)
    justify-content: space-between
    align-items: center
    padding: 0.5rem
    border-bottom: 1px solid $gray-light
    + .product
        padding-top: 1rem
    + .btn
        margin-top: 1rem
    &-title
        +make-col-ready($gutter: 0)
        width: auto
    &-status
        display: block
        color: $gray-dark
        font-weight: 300
    &-actions
        +make-col-ready($gutter: 0)
        width: auto
        +opacity-transition("out")
        .btn-link
            padding: 0
            font-weight: 300
            + .btn-link
                margin-left: 0.5rem
    &:hover
        text-decoration: none
        background:
            color: $gray-light
        .product-actions
            +opacity-transition("in")