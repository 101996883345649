.agenda-card.agenda-card__view--mode{
    .react-calendar-timeline {
        .rct-header-root,
        .rct-dateHeader{
            background-color: var(--cal-header-bg-color);
        }
        .rct-dateHeader,
        .rct-calendar-header{
            border: none;
            color:  #8a8a8a;
            padding: 0 4px;
        }

        .rct-hl-odd,
        .rct-sidebar-row.rct-sidebar-row-odd{
            background-color:rgba(247, 247, 250, 0.439);
        }

        .rct-sidebar-row{
            background-color:rgba(247, 247, 250, 0.439);
            color: #31708f;
            font-weight: 900;
        }


        .rct-dateHeader span{
            text-align: center;
            padding: 4px 20px;
            display: inline-block;
        }

    }
    .rct-sidebar-row,
    .rct-sidebar-row.rct-sidebar-row-odd,
    .rct-hl-odd,
    .rct-hl-even,
    .rct-horizontal-lines,
    .rct-header-root,
    .rct-scroll,
    .rct-vertical-lines,
    .react-calendar-timeline .rct-sidebar,
    .react-calendar-timeline .rct-vertical-lines .rct-vl
    {border-color: var(--cal-header-border-color)!important; }

    .react-calendar-timeline .rct-vertical-lines .rct-vl
    { border-left: 1px solid var(--cal-header-border-color);}
    .react-calendar-timeline {
        border: 1px solid var(--cal-header-border-color);
    }

    .rct-calendar-header >div:first-of-type{
        display: none;
    }
    .rct-calendar-header >div:last-of-type{
        height: 50px!important;
    }

    .rbc-toolbar{
        select, input, .form-control-element{
            margin-right: 4px;
            margin-bottom: 4px;
        }
        select, input{
            min-width: 200px;
            max-width: 200px;
        }
        button{
            &:disabled{
                background-color: rgb(202, 202, 202);
                border-color: rgb(202, 202, 202);
                color: rgb(119, 119, 119);
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }

    
    .rbc-toolbar.input-group.btn-only{
        justify-content: unset;
        margin-bottom: 32px;
        button{
            margin-right: 4px;
        }
    }
    .rbc-toolbar.input-group:not(.btn-only){
        flex-wrap: wrap;
        justify-content: unset;
        align-items: center;
      
    }

}

.agenda-card.agenda-card__view--mode,
.agenda-card{
    
    @media only screen and (max-width: 992) {
        overflow: hidden;
        overflow-y: scroll;

    }
}