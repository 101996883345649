.input-search
    padding:
        top: 0
        right: 0
        bottom: 0
        left: 0.5rem
    border:
        top: 0
        right: 0
        bottom: 0
        left: 0
    background: none
    width: 100%
    outline: none
    &::placeholder
        color: $gray-dark
    &-addon
        padding: 0
        border: 0
        line-height: 3rem
        background: none
        i
            font-size: 16px
    &-container
        border-bottom: 1px solid #DADAE0
        height: 3rem

input
    &::placeholder
        font-weight: 300
        color: $gray-dark

label.error
    color: $red !important

.form-group
    label
        margin-bottom: 0
        color: $black
    input[type="text"] + label
        margin-top: 1rem
    input[type="text"] + label.error
        margin-top: 0


select.form-control:not([size]):not([multiple])
    border-radius: 0
    line-height: 2
    height: calc(3rem + 2px)

=form-grid-col($number)
    padding: 0 0.5rem
    vertical-align: top
    width: 100%
    +media-breakpoint-up(sm)
        width: ($number/12)*100%
        min-width: 7rem

.form-grid
    &-row
        margin: 0 -0.5rem
        display: flex
        flex-wrap: wrap
        &.column
            +media-breakpoint-down(sm)
                flex-direction: column
    &-col
        &-1
            +form-grid-col(1)
            width: 100px
            +media-breakpoint-up(md)
                min-width: 7rem
        &-2
            +form-grid-col(2)
            +media-breakpoint-up(md)
                min-width: 10rem
        &-3
            +form-grid-col(3)
            +media-breakpoint-up(sm)
                min-width: 15rem
        &-4
            +form-grid-col(4)
            min-width: 20rem
        &-5
            +form-grid-col(5)
            min-width: 20rem
        &-6
            +form-grid-col(6)
            min-width: 20rem
        &-7
            +form-grid-col(7)
            min-width: 20rem
        &-8
            +form-grid-col(8)
            min-width: 20rem
        &-9
            +form-grid-col(9)
            min-width: 20rem
        &-10
            +form-grid-col(10)
            min-width: 20rem
        &-11
            +form-grid-col(11)
            min-width: 20rem
        &-12
            +form-grid-col(12)
            min-width: 20rem

.price-filter 
    .input-group-addon
        text-align: left
        width: 50px
    .input-group + .input-group
        margin-top: 0.5rem
    input.form-control
        // Firefox bug fix https://bugzilla.mozilla.org/show_bug.cgi?id=1088586
        min-width: calc(100% - 50px)

.radio-inline 
    display: flex
    
    .radio
        margin-right: 1rem

.input-group-addon.material-icons
    font-size: 1.5rem

.input-group-addon.v-width
    width: auto