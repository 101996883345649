.aanvraag-list
    list-style: none
    padding: 0
    margin: 
        bottom: 0
    li
        padding: 1rem 1.5rem
        border-top: 1px solid $gray-light
        transition: background-color 0.3s ease-in-out
        &:hover
            background-color: $gray-light
            transition: background-color 0.3s ease-in-out
            .aanvraag-list-action
                .btn
                    background-color: $brand-success
                    color: white
            .link-detail
                +opacity-transition("in")
        .link-detail
            font-weight: 300
            color: $brand-primary
            font-size: 0.875em
            +opacity-transition("out")
            +media-breakpoint-down(md)
                display: none
    li:last-of-type
        border-bottom: 1px solid $gray-light
    &-item
        position: relative
        display: block
        color: $body-color
        min-height: 4rem
    a
        &:hover, &:focus, &:active
            text-decoration: none
    &-naam
        display: block
        color: $black
        margin-bottom: 0.5rem
    &-status
        display: block
        color: $black
        font-weight: 300
    &-content
        font-weight: 300
    &-action
        font-weight: 300
        color: $brand-primary
        font-size: 0.875em
        @media screen and (min-width: 1200px)
            +position(absolute, null 0 0 null)
        .btn
            line-height: 1.2
            background-color: $gray-light
            color: transparentize($black, 0.75)

.dashboard-maincontent .aanvraag-list-action .btn
    background-color: $green
    color: white
    margin-top: 0.5rem
