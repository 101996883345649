.maincontent-sidebar-right
    align-self: flex-start
    padding: 0 $grid-gutter-width/2
    
    +media-breakpoint-up(lg)
        background:
            color: white
        padding: $grid-gutter-width/2
            
    box-shadow: $shadow-diffuse
    // margin-left: $grid-gutter-width/2
    .card
        border:
            top-width: 0
            right-width: 0
            bottom-width: 1px
            left-width: 0
        &-header
            +media-breakpoint-up(lg)
                padding: 0
            text-transform: uppercase
            color: $gray-dark
            background: none
            border: 0
            margin-bottom: 0.5rem
        &-footer
            margin-bottom: 1rem
            text-align: center
            .btn
                display: block
                margin: 1rem auto 0 auto
        &-block
            +media-breakpoint-up(lg)
                padding: 0
            > i:last-of-type
                margin-bottom: 1rem
                display: inline-block
        &:last-of-type
            border-bottom-width: 0

        &-action
            .btn
                display: block
                margin-bottom: 1rem