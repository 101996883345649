.expert-results-list

    +make-row(0)
    .results
        display: flex
        width: 100%
        > form
            flex-grow: 1
        .selected
            margin-left: 1rem
            width: 250px
            +media-breakpoint-down(md)
                display: none
    .filters
        +make-col(12)
        padding-right: $grid-gutter-width/2
        min-width: 200px
        background: $gray-light
        display: flex
        flex-wrap: wrap
        margin-bottom: 1rem
        align-items: baseline
        &-item
            padding: 1rem
            flex-grow: 0
            width: 200px
            .form-group
                width: 100%
            + .selected
                margin-top: 2rem
            .btn-reset
                padding: 0
                background-color: initial
                color: $gray-dark
        h3
            font-size: 1rem
        .form-group
            margin: 0
        .form-control
            background-color: white

        &.expert-filters
            padding: 12px!important
        .expert-filters-container
            display: flex
            width: 100%
            flex-wrap: wrap
            margin-right: calc(0px - 4px)
            margin-left: calc(0px - 4px)
            padding: 0!important
            align-items: baseline
            .filters-item
                padding: 0
                margin: 0
                width: 100%
            > *
                flex: 0 0 33.33%
                max-width: 33.33%
                position: relative
                width: 100%
                min-height: 1px
                padding-right: 4px
                padding-left: 4px
                @media only screen and (max-width: 800px)
                    flex: 0 0 50%
                    max-width: 50%
                    .filters-item
                        margin-bottom: 10px

                @media only screen and (max-width: 500px)
                    flex: 0 0 100%
                    max-width: 100%

                input, select
                    max-width: unset!important









    &.collapse
        display: none
        &.in
            display: flex