$formSetWidth: 4px;
.form-set{
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(0px - #{$formSetWidth});
    margin-left: calc(0px - #{$formSetWidth});

    > * {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: $formSetWidth;
        padding-left: $formSetWidth;
        input, select {
            max-width: unset!important;
        }

        @media only screen and (max-width: 800px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media only screen and (max-width: 500px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

    }
    &--location{
        width:100%;
        display: flex;
        select{
            margin-right: 4px;
            width: 120px;
        }
        &__zipcode {
            display: flex;
            width: 100%;
        }
        &__zipcode-btn{
            white-space: normal;
            text-align: right;
            padding: 0;
            margin-bottom: 8px;
        }
    }
}
