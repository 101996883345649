.tinymce-container
	position: relative
	.qq-upload-drop-area-selector
		position: absolute
	.qq-upload-list
		display: block
		padding-left: 0
		li
			border-bottom: 1px solid #d8d8d8
			border-left: 1px solid #d8d8d8
			border-right: 1px solid #d8d8d8
			display: block
			&.qq-upload-success
				display: none
	.qq-progress-bar-selector
		background-color: #0275d8
		height: 3px

	.tox-toolbar.tox-toolbar--scrolling
		@media only screen and (max-width: 600px) 
			flex-shrink: 1
			flex-wrap: wrap

