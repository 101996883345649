.modal
    &:before
        +position(fixed, 1rem null null 1rem)
        content: "g"
        display: block
        color: white
        font:
            family: lex
            size: 6rem
        line-height: 1
        +media-breakpoint-down(sm)
            top: 0
            left: 0
            font-size: 4rem
            z-index: 10
        &:hover
            cursor: pointer
    &-dialog
        +position(absolute, 0 0 0 null)
        margin: 0
        box-shadow: $shadow-modal
        width: 40%
        z-index: 4
        +media-breakpoint-down(sm)
            width: calc(100% - 4rem)
        .modal-content
            min-height: 100%
    &-auto
        width: auto
        max-width: calc(100% - 8rem)
        +media-breakpoint-down(sm)
            width: calc(100% - 4rem)
    &-xl
        width: 80%
        max-width: none
        +media-breakpoint-down(sm)
            width: calc(100% - 4rem)
    &-xxl
        width: calc(100% - #{$sidebar-width} - #{$grid-gutter-width/2})
        max-width: none
        +media-breakpoint-down(sm)
            width: calc(100% - 4rem)
    &-header
        background:
            color: $gray-light
        padding: 2rem 2rem 1rem
        input[type="text"]
            height: 4rem
            + .btn
                padding: 0
                color: $black
                font-size: 0.8333em
                font-weight: 300
                font-style: italic
                background: none
                border: none
                float: right
                strong
                    font-weight: 400
                &:hover
                    text-decoration: none
        &.modal-header-flex
            display: flex
            align-items: center
            justify-content: space-between
            h4
                flex-grow: 1
    &-body
        +media-breakpoint-down(sm)
            width: 100% !important
    .modal-dialog
        +media-breakpoint-down(sm)
            width: calc(100% - 20px)!important
    &-maincontent
        +make-col-ready()
        padding: 0
        @media (max-width: 1280px)
            width: 100%
        .dropdown
            margin-bottom: 1rem
    &-sidebar
        +make-col-ready($gutter: 0)
        width: 300px
        @media (max-width: 1280px)
            display: none

.row //tf-del
    .react-datepicker__input-container, .react-datepicker-wrapper
        width: 100%