@if $use-fadeInLeft == true {

	@-webkit-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@keyframes fadeInLeft {
		0% {
			opacity: 0;
			transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInLeft {
		@include animate-prefixer(animation-name, fadeInLeft);
	}

}
