=checklist
    padding: 0
    list-style: none
    text-align: left
    display: inline-block
    li
        font-weight: 300
        padding: 0.25rem 0
        &:before
            +material-icons
            display: inline-block
            font-size: 1.25rem
            line-height: 1
            margin-right: 0.5rem
            content: "check"
            border-radius: 0 $border-radius $border-radius 0 
            color: $brand-success
            vertical-align: middle
            transform: translate(0,-1px)

.checklist
    +checklist