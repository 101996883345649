.user-list
    +make-row($gutter: 0)
    &-item
        +make-col-ready(0)
        min-width: 19.5rem
        width: auto
        margin-bottom: 1rem
        padding-right: 0.5rem
        .card-header &,
        .rating-item-header &
            display: inline-block
            margin-bottom: 0
        &.primaryexpert
            .user-list-image
                border-color: $green
        a:hover,
        a:focus
            text-decoration: none
    a
        display: inline-block
        &:hover
            text-decoration: initial
    &-image
        width: calc(2.5rem + 4px)
        height: calc(2.5rem + 4px)
        border-radius: 50%
        background-color: $gray
        overflow: hidden
        border: 2px solid $gray
        display: inline-flex
        justify-content: center
        align-items: center
        margin-right: 0.5rem
        vertical-align: middle
        img
            +size(100%)
        .company-placeholder,
        .avatar-placeholder
            +size(75%)
        &.large
            width: calc(5rem + 4px)
            height: calc(5rem + 4px)
    &-image-success
        border-color: $green
        position: relative
    &-image-caution
        border-color: $yellow
        position: relative
    &-image-warning
        border-color: $orange
        position: relative
    &-image-blue
        border-color: $blue
        position: relative
    &-image-error
        border-color: $red
        position: relative
    &-blocked
        position: relative
        opacity: 0.5
        &:after
            @include material-icons(20px)
            @include position(absolute, 0 null 0 0)
            content: "lock"
            padding: 0.5rem
            color: $black
    &-info
        display: inline-block
        vertical-align: middle
        color: $black
        text-transform: initial
        white-space: nowrap
        max-width: 15em
        overflow: hidden
        text-overflow: ellipsis
        &.nomaxwidth
            max-width: none
    &-status
        font-weight: 300
        font-style: italic
        label
            margin: 0
            background: none
            padding: 0
            color: $black
            font-size: 1rem
            font-weight: 300
        .rating:before
            font-size: 1rem
            letter-spacing: -2px
            line-height: 1
            vertical-align: middle
        .content
    &-actions
        .btn
            display: inline-block
            padding: 0
            background: none
            color: $red
            &:hover
                color: darken($red, 5%)
        .link
            color: $brand-primary
    &-block
        .user-list-item 
            display: flex
            flex-direction: column
            align-items: center
            border: 2px solid $gray-light
            padding: 1rem
            margin-right: 1rem
        .user-list-image
            width: 60px
            height: 60px
