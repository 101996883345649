.question-list {
    margin-bottom: 2.5rem;
    &--header,
    &--block__title {
        .btn-edit-inline.delete::before {
            content: 'delete' !important;
            color: #d9534f !important;
        }
    }
    &--btn-back {
        .material-icons {
            font-size: 14px;
            margin-right: 0.2rem;
        }
    }

    &--header {
        display: flex;
        gap: 0.2rem;
        align-items: center;
        margin-bottom: 1rem;
        .btn.btn-edit-inline::before {
            line-height: unset;
        }
    }

    &--timetag {
        padding-left: 0.5rem;
        display: inline-flex;
        gap: 12px;
        font-size: 0.9rem;
        color: #a3a7c3;
    }

    &--block {
        padding-left: 0.5rem;

        &:not(:last-of-type) {
            margin-bottom: 1.5rem;
        }
        &:last-of-type p {
            margin-bottom: 0;
        }

        .activity.message {
            &:not(:last-of-type) {
                padding-bottom: 1rem;
            }
            &.question::before {
                content: 'contact_support' !important;
            }
            &.answer::before {
                content: 'feedback' !important;
            }
            b,
            p {
                padding-top: 0.5rem;
                display: block;
            }
            b {
                margin-bottom: 0.5rem;
            }
            .question-list--block__title + b {
                padding-top: 0 !important;
            }
        }
        form.alert.alert-info {
            .form-control {
                background-color: white !important;
            }
        }
        &__btn {
            display: flex;
            align-items: center; 
            gap: 0;
        }
        &__title {
            padding-top: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.4rem;
            b,
            button,
            p {
                margin-bottom: 0 !important;
                margin-inline-end: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                line-height: 14px;
            }

            button {
                display: flex;
                align-items: center;
                margin-top: 2px;
                font-size: 14px;
                &::before {
                    font-size: 15px;
                }
            }
            

            p.author {
                color: #a3a7c3;
                font-size: 0.9rem;
            }
        }

        .alert.alert-info {
            border: none;
            display: block;
            background-color: #f7f7fa;
            color: #2c2c2c;
            padding: 0.75rem;
        }
    }
    &--btn {
        display: flex;
        gap: 0.2rem;
    }

    &--add-new {
        padding-left: 0;
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-bottom: 1rem;

            .btn {
                padding: 0;
            }
        }
    }
    &--sub-title {
        padding-bottom: 0;
        text-transform: uppercase;
        color: #a3a7c3;
        background: none;
        border: 0;
        margin-bottom: 0;
    }

    .btn-add.send {
        margin-top: 1rem;
        &::before {
            content: 'send' !important;
            font-size: 14px;
        }
    }
}
