=clean-label
    white-space: initial
    text-align: left
    background: none
    padding: 0
    color: $black
    font-size: 1rem

.label
    &-fixedopdrachtgever
        +clean-label
        font-weight: 300
        color: $green
    &-fixedexpert
        +clean-label
        font-weight: 300
        color: $blue
    &-offerte
        +clean-label
        font-weight: 300
        color: $seablue