.product-list
    margin-bottom: 2rem
    &-content
        padding: 2rem 2rem 1rem 2rem
        border-top: 1px solid $gray
        border-right: 1px solid $gray
        border-left: 1px solid $gray
    &-actions
        text-align: right
        background: $gray-light
        padding: 1rem 2rem
        border-right: 1px solid $gray
        border-bottom: 1px solid $gray
        border-left: 1px solid $gray
    &-item
        margin-bottom: 1rem
    h2
        font-size: 1.5rem
        .tag
            display: inline-block
            font-size: 0.83333rem
            vertical-align: middle
    p
        max-width: 50em
        line-height: 1.5rem
        font-weight: 300