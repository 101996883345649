.page
    &-container
        +make-row($gutter: 0)
        width: 100%
        min-height: 100vh
        +media-breakpoint-down(sm)
            flex-direction: column
    &-sidebar
        +make-col-ready($gutter:0)
        background-color: $black
        min-height: 100vh
        width: $sidebar-width
        + .page-content
            width: calc(100% - #{$sidebar-width})
            +media-breakpoint-down(sm)
                width: 100%
        +media-breakpoint-down(sm)
            min-height: initial
            width: 100%
            display: flex
    &-content
        +make-col-ready($gutter:0)
        padding-bottom: 7rem
        +media-breakpoint-down(sm)
            .navbar-nav 
                display: flex
                flex-direction: column
                flex-wrap: nowrap
                align-items: flex-start
                margin-top: 1rem
                overflow-x: auto
                // margin-left: -$grid-gutter-width/2
                .align-right
                    justify-content: flex-start
                    height: 24px
                    .col
                        //white-space: nowrap
                        // margin-left: $grid-gutter-width/2
                        position: absolute
                        left: 0
            .navbar-mainmenu
                display: flex
            .navbar-link
                white-space: nowrap

    &-title
        align-items: center
        h1
            font:
                size: 1.5em
                weight: 400
            line-height: 2
            color: $dark-blue
            display: inline-block
            margin:
                right: 1.25rem
                bottom: 0
            vertical-align: middle
        .actionbar
            display: inline-block
            padding: 0rem 1.25rem
            border-left: 1px solid #DADAE0
            vertical-align: middle
            .btn
                margin-top: 1px
                margin-bottom: 1px
            .btn:first-of-type + .btn
                margin-left: 1px
            .dropdown .btn
                height: 31px
                margin: 0
                padding-left: 1rem !important
                padding-right: 1rem !important
                color: $black
                line-height: 1rem
            .dropdown
                margin-right: 0.5rem
                display: inline-block
            form
                display: inline-block
        // .input-search-container
        //     padding:
        //         top: 0.25rem
        //         right: 1rem
        //         bottom: 0.25rem
        //         left: 2.5rem
    &-copyright
        +position(absolute, null null 1.6665rem 1.6665rem)
        line-height: 3
        opacity: 0.5
        z-index: -1
        &:hover
            opacity: 1
        a
            color: $body-color
            &:hover, 
            &:active,
            &:focus
                text-decoration: none
