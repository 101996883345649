.dashboard
    &-maincontent
        .input-search-container
            margin-bottom: 2rem
        &-col
            +make-col-ready
            +media-breakpoint-up(sm)
                +make-col(6)
                + .dashboard-maincontent-col
                    padding-left: 0
        .logo-container-large
            text-align: center
            img.companylogo
                max-width: 100%
                max-height: 15rem
            img.companylogo-half
                max-width: 45%
                max-height: 15rem
            .plus
                font-family: lex
                color: $gray-dark
    &-sidebar
        .btn 
            display: block
            text-align: center
            margin-bottom: 1.5rem
    &-quickactions
        .btn:first-of-type
            margin-bottom: 0
        .btn + .btn
            margin-top: 0.5rem
            margin-bottom: 0
        .btn:last-of-type
            margin-bottom: 1.5rem