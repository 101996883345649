body{
	--cal-header-bg-color:#F7F7FA;
	--cal-header-border-color:#EFEFEF;

	.rbc-time-gutter.rbc-time-column{
		--cal-header-border-color:#DDD;
	}
}

.agenda-card{
	height: calc(100vh - 220px); 
	background: white; 
	padding: 1.5rem;
	overflow: scroll;
	display: block;
}
.calendar-header{
	margin-top: 12px;
}
.rbc-calendar{
	margin-top: 20px;
	div{
		border-color: var(--cal-header-border-color);
	}
	.rbc-time-view{
		background: var(--cal-header-bg-color);
		
		// ---- Calendar header ---
		// ------------------------
		.rbc-time-header{
			background: var(--cal-header-bg-color);
			.rbc-header{
				border-bottom: none;
				a:hover span{
					color: #31708f;
				}
			}
		}

		// ---- Calendar body ---
		// ----------------------
		.rbc-time-content{
			border-top: none;
			background: #FFF;
			overflow: unset;
			.rbc-time-gutter.rbc-time-column{
				background: var(--cal-header-bg-color);
				color:  #8a8a8a
			}
		}
	}

	.rbc-today{
    	background-color: #d9edf7;
    	border-color: #bcdff1;
    	color: #31708f;
    	a:hover span{
			color: darken(#31708f, 15%);
		}
		.rbc-timeslot-group{
			border-color: darken(#d9edf7, 8%);
		}
		&.rbc-day-slot.rbc-time-column.rbc-now{
			.rbc-time-slot {
			    border-color: darken(#d9edf7, 8%);
			}	
		}
	}
}

