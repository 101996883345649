=avatar-container($size: 25px)
    background:
        image: url("../img/avatar-placeholder.svg")
        size: 40%
        repeat: no-repeat
        position: center
    width: $size
    height: $size
    border-radius: 50%
    overflow: hidden
    img
        +size(100%)
        max-width: 100%
        max-height: 100%

.avatar-container
    &-small
        +avatar-container(25px)
        background-color: $gray-light
    &-medium
        +avatar-container(60px)
        background-color: white
        box-shadow: $shadow-diffuse
        display: inline-block
        margin-right: 0.5rem
        vertical-align: middle
    &-large
        +avatar-container(120px)

