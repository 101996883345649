.product-box
    text-align: center
    border: 2px solid $gray-light
    margin-bottom: 2rem
    display: flex
    flex-direction: column
    justify-content: space-between
    flex: 0 0 33.33333%
    max-width: 33.33333%
    +media-breakpoint-down(lg)
        flex: 0 0 40%
        max-width: 40%
    +media-breakpoint-down(md)
        flex: 0 0 80%
        max-width: 80%
    &-content
        padding: 2rem
    h2
        font-size: 1.5rem
    p
        line-height: 1.5rem
        font-weight: 300
        &:last-of-type
            margin-bottom: 0
    ul
        list-style: none
        padding: 0
        // margin: 0
    .btn
        display: block
    + .icon-box
        color: $gray
        align-self: center
        +media-breakpoint-down(md)
            display: none
    &-footer
        background: $blue
        color: white
        font-size: 1.25rem
        padding: 0.5rem 2rem
        