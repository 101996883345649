@mixin dropdownDelayStructure ($background, $border){
    background: $background;
    border: $border;
}
@mixin dropdownDelayPosition{
    top: 0;
    transform: translateY(7px);
    position: absolute;
}
@mixin dropdownDelayIcon ($icon){
    content: $icon;
    font-size: 14px;
    font-family: 'Material Icons';
    pointer-events: none;
    @include dropdownDelayPosition;
}
.dropdown{
    &.select{
        @include dropdownDelayStructure (white, 1px solid #DADAE0);
        position: relative;
        color: #1F212A;
        height: 30px;
        overflow: hidden;
        width: 136px;
        
        &::before{
            @include dropdownDelayIcon ('history');
            left: 4px;
        }
        &::after{
            @include dropdownDelayIcon ('arrow_drop_down');
            right: 4px;
        }
        select{
            appearance: none;
            @include dropdownDelayStructure (none, none);
            padding: 0 16px 20px 20px;
            font-size: 14px;
            left: 1px;
            right: 1px;
            width: 100%;
            @include dropdownDelayPosition;
        }
    }
    .btn.dropdown-toggle {
        padding: 3px 1rem !important;
        display: flex;
        align-items: center;
        i.material-icons {
            margin-right: 0.5rem;
            line-height: 22px;
        }
    }
}
.activity{
    &.dashboard-timeline{
        .activity-date{
            display: block;
            line-height: 24px;
            margin-left: 0;
        }
        .activity-name{
            line-height: 12px;
            display: block;
        }
        .activity-content{
            p{
                margin-bottom: 2px;
            }
            a{
                &:first-of-type{
                    margin-right: 12px;
                }
            }
        }
        .activity-actions{
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            .btn-success {
                padding: 0;
                i{
                    line-height: 22px;
                    font-size: 1rem;
                    padding: 3px 1rem !important;
                }
            }
            form {
                line-height: initial;
            }
        }
        i.material-icons {
            font-size: 1rem;
        }
    }
    &.dashboard-timeline-compact {
        .btn.dropdown-toggle {
            padding: 3px 0.5rem !important;
        }
        // .activity-actions{
        //     position: unset;
        //     margin: 0.5rem 0;
        // }
        .dropdown-toggle-text {
            display :none;
        }
    }
}
.activity{
    &.message{
        &.warning{
            &:before{
                content: "warning";
                color: $blue;
                background: #EFF8FF;
            }
        }
    }
}
