.datepicker
    &-inputs
        display: inline-block
        + .btn-calendar
            margin-left: 0.5rem
    &-result
        display: block
    &-inline .datepicker-result
        display: inline-block

.datepicker, .datetimepicker
    .date-error
        border-color: red
    i
        padding: 0 0.5rem

    .date-input
        display: inline-block
        width: 3em

        &.large // for year inputs, more digits than month/day
            width: 5em