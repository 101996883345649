$success: #71BD1E;


.wizard.wizard-horz{
    // margin-bottom: 1.5rem;
    display: flex;
    background: none;
    margin: 0 -2px 1.5rem;
    .wizard-step.active{
        background-color: $success;
        color: white;
    }
    .wizard-step{
        margin: 0 2px;
        border-radius: 4px;
        padding: 0.75rem 1.5rem;
        font-size: 12px;
        font-weight: 500;
        color: rgba(163, 167, 195, 0.87);

        &.checked::after{
            display: none;
        }
    }
}