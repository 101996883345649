.btn-group.week-picker{
    display: flex;
    padding: 4px 12px;
    border: 1px solid #EFEFEF;
    border-radius: 2px;
    justify-content: space-between;
    align-items: center;
    color: #202020;
    font-weight: 600;
    margin-bottom: 20px;
    .btn.btn-link{
        padding: 0;
        font-size: 12px;
        padding-top: 4px;
        &:hover{
            text-decoration: none;
        }
    }
}