.tag-list
    &-title
        font-size: 1rem
        font-weight: 400
        color: $gray-dark
        margin-bottom: 0.5rem
    &-content
        margin-bottom: 1rem
        position: relative
        &.active
            li:not(.taggle)
                min-width: 100%
