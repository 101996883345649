// /*
//     This file is a copy of the bootstrap variables file. 
//     You can customize the look of bootstrap in this file. 
//     When you want to make your own components. 
//     You can add them to this file OR you can create a separate file to keep track of your custom variables. 
//     This file can be called "custom-settings.sass". 
//     After you created this file you have to import this into the master.sass ofcourse.
// */

// Custom variables

// Colors
$dark-blue: #2A3775
$blue: #598ED9
$seablue: #66BEDE
$orange: #EF8229
$yellow: #FAF392
$green: #71BD1E
$seagreen: #AC96D9
$red: #d9534f

$black: #1F212A
$alt-white: #F6F9FB

$black: #1F212A
$gray-dark: #A3A7C3
$gray: #EAEBF2
$gray-light: #F7F7FA

$brand-success: $green

$body-color: $black
$input-color: $black

// Font-weights
$global-weight-light: 300
$global-weight-book: 400
$global-weight-medium: 500
$global-weight-semibold: 600

$global-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15)
$global-transition: .3s ease-in-out

$global-block-spacing: 6rem

// Z-indexes
$z0: 0
$z1: 1
$z2: 2
$z3: 3

$row-padding: 1.38462rem

// Sizes
$topbar-height: 54px
$sidebar-width: 100px
$sidebar-title-height: 80px

// Shadows
$shadow-diffuse: none
$shadow-modal: 0px 0px 20px 0px #626577

// Bootstrap variables

// Variable format should follow the `$component-modifier-state-property` order.

// Copy settings from the '_variables.scss' file into this file to override
// the Bootstrap defaults without modifying key, versioned files.

$grid-gutter-width: 3.333rem !default // 40px

$font-family-sans-serif: "myriad-pro", Helvetica, Arial, sans-serif !default
$font-size-root: 13px

$border-radius: 1px
$border-radius-lg: 1px
$border-radius-sm: 1px

$navbar-padding-horizontal: 0

$card-border-color: #EAEBF2
$card-border-width: 0

$card-spacer-x: 1.5rem
$card-spacer-y: 1.5rem
$card-cap-bg: $gray-light

$table-border-color: $gray-light

// Padding applied to the modal body
$modal-inner-padding: 2rem

$modal-title-padding: 1rem

$modal-content-border-color: transparent

$modal-backdrop-bg: rgba(31,32,42,0.60)
$modal-backdrop-opacity: 1
$modal-header-border-color: $gray

$input-border-color: $gray

$use-fade: true

// Tags
// $tag-default-bg:            $gray-light !default;
// $tag-primary-bg:            $brand-primary !default;
// $tag-success-bg:            $brand-success !default;
// $tag-info-bg:               $brand-info !default;
// $tag-warning-bg:            $brand-warning !default;
// $tag-danger-bg:             $brand-danger !default;
$tag-color: white
$tag-font-size: 0.8333333333em
$tag-font-weight: 400
$tag-padding-x: .75rem
$tag-padding-y: 0.25rem

$input-line-height: 2
$input-bg: $gray-light
$input-border-color: $gray
$input-border-focus: darken($gray, 5%)


$tag-pill-padding-x: .6em
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius: 10rem



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (xs: 0, sm: 544px, md: 768px, lg: 992px, xl: 1400px)

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (sm: 576px,md: 720px,lg: 940px,xl: 1340px)

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-color: $black
$dropdown-link-hover-color: $black
$dropdown-link-hover-bg: $gray-light

$dropdown-link-active-color: $black
