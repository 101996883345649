.box-user
    text-align: center
    border: 1px solid $gray-light
    margin-bottom: 1rem
    padding: 1rem
    width: 220px
    display: block
    color: $black
    &:hover, &:focus
        border: 1px solid $brand-primary
        text-decoration: none
        background: #F7F7FA
    .user-list-image
        display: block
        margin: 0 auto
    .box-user-header
        margin-bottom: 1rem
    .box-user-content
        margin-bottom: 1rem
    .box-user-footer