.rating-item
    display: block
    color: $body-color
    padding: $card-spacer-x
    border: 1px solid $gray-light
    &:hover, &:focus
        text-decoration: none
        background: $gray-light
    + .rating-item
        margin-top: $card-spacer-y
    &-header
        padding-bottom: $card-spacer-y
    .user-list-info
        max-width: none
        white-space: normal

.rating
    label.btn.btn-reset
        padding: 0

.checkbox-rating-list
    display: flex
    flex-wrap: nowrap
    align-items: center
    .ratings-output
        vertical-align: middle
        font-size: .875rem
        font-style: normal
        line-height: 1
        color: #a3a7c3
        font-weight: 300
    .rating
        line-height: 0
        &-label
            line-height: 0
            &:before
                +material-icons
                color: #F7CB50
                content: "star_border"
            &:hover
                cursor: pointer
                &:before
                    +material-icons
                    content: "star"
    .rating-label-checked
        &:before
            +material-icons
            content: "star"
    .btn-cancel
        padding: 0
        color: $brand-primary
        font-size: 0.75rem