.nav-tabs
    .nav-link.active,
    .nav-link:focus,
    .nav-link:hover,
    .nav-link.active:focus,
    .nav-link.active:hover
        color: $black
        border-top: 2px solid $brand-primary
    .nav-link
        color: $black
        border-top: 2px solid transparent

.tab-nav-item
    