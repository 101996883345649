
  .expert-list-box6{
    display: none;
}
.expert-list-selection{
    .expert-list-box4 .expert-list-price h3 span{
        display: block;
        &:last-of-type {
            font-size: 12px;
            font-weight: 400;
            color: #202020;
        }
    }

    .expert-list-box4, .expert-list-box2 {
        .content,
        .content h2,
        h2{
            color: #202020;
        }
    }
    .expert-list-price--icon {
        display: flex!important;
        align-items: center;
        justify-content: flex-end;
    }


    @media only screen and (max-width: 992px) {
        display: flex;

        .expert-list-box1,
        .expert-list-box2 .expert-list-statusbars,
        .expert-list-box4 .expert-list-price{
            display: flex;
            align-items: center;
            margin-right: calc(0px - 4px);
            margin-left: calc(0px - 4px);
            flex-wrap: wrap;
            > * {
                padding-right: 4px;
                padding-left: 4px;
            }
            .expert-list-distance,
            .expert-list-logo{
                margin-bottom: 0;
            }
        }
        .expert-list-box3,
        .expert-list-box5 {
            padding-top: 0;
            padding-bottom: 0;
        }
        
        .expert-list-box1{
            padding-bottom: 0;
            order: 1;
            .expert-list-users,
            .expert-list-logo{
                height: 100%;
                width: unset;
            }
        }
        .expert-list-box2 {
            order: 3;
            margin-bottom: 12px;
            padding-left: 1rem;
            padding-right: 1rem;
            .expert-list-statusbars{
                .expert-list-rating,
                .expert-list-distance{
                    padding-top: 0;
                    padding-bottom: 0;
                    border: none;
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    position: relative;
                    width: 100%;
                    min-height: 1px;
                    text-align: left;
                }
            }
        }
        .expert-list-box3{
            order:4;
        }
        .expert-list-box4{
            padding-bottom: 4px;
            order: 2;

            .expert-list-price {
                > div:first-of-type {
                    h2{
                        margin-bottom: 0;
                    }
                }
                align-items: baseline;
                > *{
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    position: relative;
                    width: 100%;
                    min-height: 1px;
                    text-align: left;
                }
                .expert-list-price--icon {
                    justify-content: flex-start;
                }
            }
            }
            .expert-list-select{
                display: none;
        }
        .expert-list-box5{
            order: 6;
            padding-top: 8px;
            padding-bottom: 8px;
        }
        .expert-list-box6{
            order: 5;
            display: block;
            padding: 1rem;
            text-align: left;
        }

        .expert-list-box3 {
            -webkit-line-clamp: 4 !important;
            -webkit-box-orient: vertical !important;
            overflow: hidden !important;
            max-height: 103px;
           
        }
    }
    @media only screen and (max-width: 600px) {
        .expert-list-box4  .expert-list-price {
            > *{
                flex: 0 0 50%;
                max-width: 50%;
                padding-bottom: 4px;
            }
        }
        .expert-list-box2 {
            .expert-list-statusbars{
                .expert-list-rating,
                .expert-list-distance{
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }
    }
}



.expert-list-item.expert-list-selection.chosen{
        background: #eaeafb!important;
}


.alert-short-notice.alert-warning {
    text-align: left!important;
    font-weight: 600!important;
    position: relative;
    padding-left:44px;
    border-color: #ebe2ba;
    * {
        position: relative;
        z-index: 2;
    }
    .material-icons{
        font-size: 32px;
        opacity: 0.3;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        z-index: 1;
    }
    .expert-list-box5 &{
        margin-bottom: 8px;
    }

    .expert-list-item.chosen & {
        background-color: #dcdcf3;
        border-color: #c1c1dc;
        color: #31708f;
        .material-icons{
            opacity: 0.5;
        }
    }
}