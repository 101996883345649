.button-tab{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1px;
    margin-bottom: 20px;
    justify-content: center;
    button{
        margin:  1px;
        font-size: 13px;
        padding: 4px 12px;
        border-radius: 2px;
        span{
            pointer-events: none;
            display: block;
        }
        &:hover:not(:disabled) {
            color: #0275d8;
            background-color: #f0f8ff;
            border-color: #0275d8;
        }
    }
    
    &.button-tab--vert{
        flex-direction: column;
        button{
            text-align: left;
            &:disabled {
                opacity: 0.3;
            }

            > span:last-of-type{
                white-space: initial;
                text-align: right;
                font-size: 12px;
                padding-left: 8px;
            }
        }
        .btn.btn-primary.btn-time-slot:not(.active){
            color: #A3A7C3;
            background-color: #fff;
            border-color: #ccc;
            font-size: 14px;

            &:hover:not(:disabled) {
                color: #0275d8;
                background-color: #f0f8ff;
                border-color: #0275d8;
            }
        }
        .alert-short-notice.btn.btn-primary.btn-time-slot{  
            &:not(.active) {
                border-color: #ebe2ba;
                background-color: #fffef6;
                color: #8a6d3b; 
                
                &:hover{
                    color: #8a6d3b;
                    border-color: darken(#ebe2ba, 15%);
                    background-color: darken(#fffef6, 10%);
                }
            } 
            &.active {
                &:hover{
                    color: white;
                    border-color:#0275d8;
                    background-color: #0275d8;
                }
            }
            font-size: 16px;
            display: flex;
            align-items: center;
            i{
                font-size: 15px;
                padding-top: 2px;
                padding-right: 4px;
            }
        }
    }
    
    @media only screen and (max-width: 912px) {
        button{
            padding: 4px 6px;
            display: block;
            text-align: center;
            flex-grow: 1;
        }
        &:not(.button-tab--vert){
            button{
                > span {
                    &:last-of-type{
                        font-weight: 700;
                    }
                }
            }
        }
        &.button-tab--vert{
            border-top: 1px solid #f0f0f0;
            padding-top: 20px;
            flex-direction: row;
            justify-content: flex-start;
            button{
                max-width: 60px;
                min-width: 60px;
                > span:last-of-type{
                    text-align: left!important;
                    padding-left: 0!important;
                }
                
                > span {
                    display: block;
                    &:first-of-type{
                        font-weight: 700;
                    }
                }
            }
            .alert-short-notice{
                max-width: 74px;
                min-width: 74px;
                > span {
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 620px) {
        &:not(.button-tab--vert){
            button{
                max-width: 52px;
            }
        }
    }
}
.btn-flex{
    display: flex;
    justify-content: space-between;
}
.card-header__selection{
    color: #1F212A;
    text-transform: none;
    font-size: 14px;
    b{
        color: #0275d8;
    }
}
.warning-block{
    width: 100% !important;
    margin: 20px 0;
    display: block;
    text-align: center;
}