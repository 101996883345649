.loginform
    @extend .animated
    @extend .fadeInUp
    &-container
        height: 100%
        > .row
            height: 100%
    &-body
        height: 100%
        +media-breakpoint-down(md)
            overflow: hidden
    &-default
        +loginform
    h1
        font-weight: 300
        line-height: 1.5
    em
        color: $orange
        font-style: normal
    &-left
        background:
            color: $gray-light
            repeat: no-repeat
            size: cover
            position: right
        width: calc(100% - 450px)
        +media-breakpoint-down(md)
            width: 100%
            height: 100%
        +media-breakpoint-down(sm)
            // display: none
    &-right
        padding:
            top: 7rem
            right: 3rem
            left: 3rem
            bottom: 1.5rem
        height: 100%
        background:
            color: white
        box-shadow: 0px 0px 20px 0px transparentize(#626577, 0.75)
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 450px
        +media-breakpoint-down(md)
            padding-top: 3rem
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            height: auto
        +media-breakpoint-down(sm)
            padding-top: 3rem
            max-width: 90%
    &-header
        padding: 1rem 0
        text-align: center
    &-logo
        background:
            color: white
        margin-bottom: 1rem
        display: block
        text-align: center
        img
            max-width: 80%
            max-height: 10rem
    
    &-content
        background:
            color: white
        margin-bottom: 0.5rem
    &-footer
        color: $gray-dark
        font-weight: 300
        display: flex
        justify-content: space-between
    input[type="text"],
    input[type="email"],
    input[type="password"]
        // line-height: 2.5
        font-size: 1.25rem
        height: 55px
    button[type="submit"]
        display: block
        width: 100%
        padding: 1rem 2rem
        font-size: 1.25rem
    &-copyright
        align-self: flex-end
        text-align: right
        color: $black
        opacity: 0.5
        margin-top: 1.5rem
        margin-right: -1.5rem
        a
            color: $body-color
            &:hover, 
            &:active,
            &:focus
                color: $body-color
                text-decoration: none
        &:hover
            opacity: 1
.validation-summary-errors
    ul
        list-style: none
        padding: 0
        margin: 0

.loginform-default .loginform-left
    background:
        position: center right