.sidebar
    &-link
        color: white
        padding: 1em
        display: block
        border-left: 2px solid transparent
        text-align: center
        position: relative
        line-height: 1
        font-size: 11px
        +media-breakpoint-down(sm)
            height: 100%
        i
            display: inline-block
            +size(40px)
            line-height: 40px
            font-size: 30px
        button
            background: none
            color: white
            border: 0
        &form
            height: $sidebar-title-height
        .nav-label
            display: block
            text-align: center
            line-height: 14px
        + .haschildren
            list-style: none
            padding: 0
        &.active
            color: white
            background-color: #313545
        &:hover, &:focus, &:active
            color: white
            text-decoration: none
        .label-pill
            +position(absolute, 0.5em 2em null null)
            background-color: $red
            min-width: 1.5rem
            border-radius: 1.5rem
            line-height: 1.5rem
            padding: 0 0.5rem
    &-title
        display: flex
        padding: 4px
        align-items: center
        justify-content: center
        background:
            color: white
        height: $sidebar-title-height
        overflow: hidden
        +media-breakpoint-down(sm)
            width: $sidebar-title-height
        img
            max-width: 100%
            height: auto
            max-height: 100%
            width: auto
        + .nav
            +media-breakpoint-down(sm)
                width: calc(100% - #{$sidebar-title-height})
                display: flex
                overflow: auto

.haschildren .sidebar-link
    padding-left: 3em
