.modal
    &.fade .modal-dialog
        opacity: 1
    &:not(.modal-multistep)
        &.in
            .modal-dialog
                animation: slideInRight 0.3s both ease-out
                &.modal-hide
                    transform: translateX(80%)
        &:not(.in) .modal-dialog
            animation: slideOutRight 0.3s both ease-out

.fade:not(.modal-backdrop)
    opacity: 1
    transition: none

.modal.fade .modal-dialog.modal-hide
    animation: slideOutRight 0.3s both ease-out
    transform: none
    transition: none
    opacity: 1

.modal.fade .modal-dialog.modal-show
    animation: slideInRight 0.3s both ease-out
    transform: none
    opacity: 1

@keyframes slideInRight
    from
        transform: translateX(100%)
    to
        transform: translateX(0)

@keyframes slideOutRight
    from
        transform: translateX(0)
    to
        transform: translateX(100%)