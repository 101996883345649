.searchexpert
    &-title
        font-size: 1.5rem
        color: $gray-dark
        margin-bottom: 1rem
    .cousinproduct-list,
    .cousinproduct-list li
        margin: 0
    .cousinproduct-list
        display: flex
        li + li
            margin-left: 1rem
        li
            margin-bottom: 1rem
    .filters-item 
        .form-group
            display: inline-block
            width: auto
            + .form-group
                margin-left: 1rem

    &.nevenproducten
        width: 100% !important
        li
            flex-shrink: 0
    .form-group
        label:not(.form-check-label)
            display: block
            font-weight: bolder
    label.product
        justify-content: flex-start
        cursor: pointer
        margin: 0
        span
            border: none!important
        &:before
            content: ""
            display: inline-block
            border: 1px solid $gray
            width: 2rem
            height: 2rem
            border-radius: 50%
            line-height: 2rem
    input[type="checkbox"]
        display: none
        &:checked + label.product:before
            +material-icons
            background: $green
            border-color: $green
            content: "check"
            color: white


    