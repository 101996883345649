.block-leave
    .editing
        display: flex
        flex-flow: row wrap
        padding: 1rem
        border: 1px solid $gray-light
        margin-bottom: 1rem
        position: relative
        > div:not(:last-of-type)
            width: 100%
    .viewing
        display: flex
        flex-flow: row wrap
        padding: 1rem
        border: 1px solid $gray-light
        margin-bottom: 1rem
        position: relative
        > div:not(:last-of-type)
            flex-grow: 0
            flex-shrink: 0
            max-width: 100%
    h2
        font-size: 1rem
        color: $gray-dark
    .leave-startdate
        margin-bottom: 1rem
        margin-right: 2rem
        .datepicker-result
            display: inline-block
    .leave-enddate
        margin-bottom: 1rem
        margin-right: 2rem
        flex-grow: 0
        .datepicker-result
            display: inline-block
    .leave-fte
        margin-bottom: 1rem
        margin-right: 2rem
        // width: 100%
    .leave-description
        margin-right: 1rem
    .leave-actions
        position: absolute
        top: 0
        right: 0

