=tablesorter-icon
    // font-family: 'lex'
    display: inline-block
    margin-left: 1rem
    vertical-align: bottom
    // background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwMS45OTggNDAxLjk5OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxLjk5OCA0MDEuOTk4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTczLjA5MiwxNjQuNDUyaDI1NS44MTNjNC45NDksMCw5LjIzMy0xLjgwNywxMi44NDgtNS40MjRjMy42MTMtMy42MTYsNS40MjctNy44OTgsNS40MjctMTIuODQ3ICAgIGMwLTQuOTQ5LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg1TDIxMy44NDYsNS40MjRDMjEwLjIzMiwxLjgxMiwyMDUuOTUxLDAsMjAwLjk5OSwwcy05LjIzMywxLjgxMi0xMi44NSw1LjQyNEw2MC4yNDIsMTMzLjMzMSAgICBjLTMuNjE3LDMuNjE3LTUuNDI0LDcuOTAxLTUuNDI0LDEyLjg1YzAsNC45NDgsMS44MDcsOS4yMzEsNS40MjQsMTIuODQ3QzYzLjg2MywxNjIuNjQ1LDY4LjE0NCwxNjQuNDUyLDczLjA5MiwxNjQuNDUyeiIgZmlsbD0iIzAwMDAwMCIvPgoJCTxwYXRoIGQ9Ik0zMjguOTA1LDIzNy41NDlINzMuMDkyYy00Ljk1MiwwLTkuMjMzLDEuODA4LTEyLjg1LDUuNDIxYy0zLjYxNywzLjYxNy01LjQyNCw3Ljg5OC01LjQyNCwxMi44NDcgICAgYzAsNC45NDksMS44MDcsOS4yMzMsNS40MjQsMTIuODQ4TDE4OC4xNDksMzk2LjU3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI4LDEyLjg1LDUuNDI4czkuMjMzLTEuODExLDEyLjg0Ny01LjQyOGwxMjcuOTA3LTEyNy45MDYgICAgYzMuNjEzLTMuNjE0LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0OGMwLTQuOTQ4LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg0N0MzMzguMTM5LDIzOS4zNTMsMzMzLjg1NCwyMzcuNTQ5LDMyOC45MDUsMjM3LjU0OXoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K)
    width: 0.5rem
    height: 1.5rem
    background-repeat: no-repeat
    background-size: contain
    background-position: center

.table-overview
    background:
        color: white

.table-container
    overflow: auto
    max-width: 100%
    .table
        white-space: nowrap
        th
            white-space: wrap

.table
    table-layout: fixed
    thead th
        border-top: 0
        padding-top: 0
        text-transform: uppercase
        color: $gray-dark
        font-weight: 400
        &:first-of-type
            padding-left: 1rem
        a:not(.btn)
            color: $blue
            &:after
                content: ""
                background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwMS45OTggNDAxLjk5OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxLjk5OCA0MDEuOTk4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTczLjA5MiwxNjQuNDUyaDI1NS44MTNjNC45NDksMCw5LjIzMy0xLjgwNywxMi44NDgtNS40MjRjMy42MTMtMy42MTYsNS40MjctNy44OTgsNS40MjctMTIuODQ3ICAgIGMwLTQuOTQ5LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg1TDIxMy44NDYsNS40MjRDMjEwLjIzMiwxLjgxMiwyMDUuOTUxLDAsMjAwLjk5OSwwcy05LjIzMywxLjgxMi0xMi44NSw1LjQyNEw2MC4yNDIsMTMzLjMzMSAgICBjLTMuNjE3LDMuNjE3LTUuNDI0LDcuOTAxLTUuNDI0LDEyLjg1YzAsNC45NDgsMS44MDcsOS4yMzEsNS40MjQsMTIuODQ3QzYzLjg2MywxNjIuNjQ1LDY4LjE0NCwxNjQuNDUyLDczLjA5MiwxNjQuNDUyeiIgZmlsbD0iIzAwMDAwMCIvPgoJCTxwYXRoIGQ9Ik0zMjguOTA1LDIzNy41NDlINzMuMDkyYy00Ljk1MiwwLTkuMjMzLDEuODA4LTEyLjg1LDUuNDIxYy0zLjYxNywzLjYxNy01LjQyNCw3Ljg5OC01LjQyNCwxMi44NDcgICAgYzAsNC45NDksMS44MDcsOS4yMzMsNS40MjQsMTIuODQ4TDE4OC4xNDksMzk2LjU3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI4LDEyLjg1LDUuNDI4czkuMjMzLTEuODExLDEyLjg0Ny01LjQyOGwxMjcuOTA3LTEyNy45MDYgICAgYzMuNjEzLTMuNjE0LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0OGMwLTQuOTQ4LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg0N0MzMzguMTM5LDIzOS4zNTMsMzMzLjg1NCwyMzcuNTQ5LDMyOC45MDUsMjM3LjU0OXoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K)
                +tablesorter-icon
    tbody
        tr:hover
            background:
                color: $gray-light
            .link-detail
                +opacity-transition("in")
        td
            vertical-align: middle
        td:first-of-type
            padding-left: 1rem
            position: relative
        .action
            td:first-of-type:before
                display: inline-block
                content: ""
                width: 0.5rem
                height: 0.5rem
                margin-right: 0.5rem
                border-radius: 50%
                background: $brand-danger
                vertical-align: middle
        .unread
            td:first-of-type:before
                display: inline-block
                content: ""
                width: 0.5rem
                height: 0.5rem
                margin-right: 0.5rem
                border-radius: 50%
                background: $brand-primary
                vertical-align: middle
        a:not(.btn)
            color: $black
            font-weight: 300
            &:hover
                cursor: pointer
                text-decoration: none
    tfoot
        tr td
            border-top-width: 2px
            font-weight: $global-weight-semibold
            border-color: #EAEBF2
    .label-default
        +clean-label
        font-weight: 300
    .label-primary
        +clean-label
        font-weight: 400
    .link-detail
        font-weight: 300
        color: $brand-primary
        font-size: 0.875em
        +opacity-transition("out")
    &-rating
        td
            vertical-align: middle

.table-collapsible
    thead th
        +media-breakpoint-down(md)
            display: none
    tbody
        +media-breakpoint-down(md)
            display: flex
            flex-wrap: wrap
        +media-breakpoint-down(md)
            tr
                display: flex
                flex-direction: column
                padding: 0 1rem
                margin-bottom: 1rem
                flex: 1 0 220px
                // margin-left: -$grid-gutter-width / 2
                // margin-right: -$grid-gutter-width / 2
                // &:last-of-type
                //     flex-grow: 0
            td
                border:
                    top: none
                    right: 1px solid $gray
                    left: 1px solid $gray
                text-align: center
                padding-top: 0
                padding-bottom: 0
                &:first-of-type
                    padding-top: 1rem
                    border-top: 1px solid $gray
                &:last-of-type
                    padding-top: 1rem
                    padding-bottom: 1rem
                    border-bottom: 1px solid $gray
            .btn-add
                margin-top: 1rem
        // +media-breakpoint-down(xs)
        //     tr
        //         padding: 0
    tfoot
        tr td
            border-top-width: 2px
            font-weight: $global-weight-semibold
            border-color: #EAEBF2
    a:not(.btn)
        color: $black
        font-weight: 300
        &:hover
            cursor: pointer
            text-decoration: none
    .label-default
        +clean-label
        font-weight: 300
    .label-primary
        +clean-label
        font-weight: 400
    .link-detail
        +media-breakpoint-down(md)
            display: none

.table-pricing
    table-layout: fixed
    thead th
        border-top: 0
        padding-top: 0
        text-transform: uppercase
        color: $gray-dark
        font-weight: 400
        &:first-of-type
            padding-left: 1rem
        tr:hover
            background:
                color: $gray-light
            .link-detail
                +opacity-transition("in")
        td
            vertical-align: middle
        td:first-of-type
            padding-left: 1rem
            position: relative
        .action
            td:first-of-type:before
                display: inline-block
                content: ""
                width: 0.5rem
                height: 0.5rem
                margin-right: 0.5rem
                border-radius: 50%
                background: $brand-danger
                vertical-align: middle
        .unread
            td:first-of-type:before
                display: inline-block
                content: ""
                width: 0.5rem
                height: 0.5rem
                margin-right: 0.5rem
                border-radius: 50%
                background: $brand-primary
                vertical-align: middle
    tbody th
        white-space: nowrap
        padding-left: 1rem
    tfoot
        tr td
            border-top-width: 2px
            font-weight: $global-weight-semibold
            border-color: #EAEBF2
    a:not(.btn)
        color: $black
        font-weight: 300
        &:hover
            cursor: pointer
            text-decoration: none
    .label-default
        +clean-label
        font-weight: 300
    .label-primary
        +clean-label
        font-weight: 400
    .link-detail
        font-weight: 300
        color: $brand-primary
        font-size: 0.875em
        +opacity-transition("out")
        // +media-breakpoint-down(md)
        //     display: none
    &-rating
        td
            vertical-align: middle
    .input-group input
        width: 100px
        flex-grow: 0
        flex-shrink: 0


td
    .input-group + .input-group
        margin-top: 0.5rem

.table-crossed
    tbody tr:nth-child(2n + 1)
        background-color: $gray-light
    td:nth-child(2n)
        background-color: transparentize($gray, 0.5)

    tbody tr:nth-child(2n + 1):hover
        background-color: $gray-light

    tbody tr:nth-child(2n):hover
        background-color: white

.table-fpeexpert
    td:not(:first-of-type)
        text-align: center
    .user-list-item
        margin: 0

