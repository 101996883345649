.staffel-inputs
    overflow: auto
    .table
        width: 100%
        white-space: nowrap
        td
            vertical-align: top
    .form-control
        // width: 100px
        // flex-grow: 0
    label.error
        white-space: normal