.navbar
    font-size: 1rem
    margin-bottom: 2rem
    background:
        color: $gray-light
    padding:
        top: 0.5rem
        right: $grid-gutter-width/2
        bottom: 0
        left: $grid-gutter-width/2
    height: $sidebar-title-height
    .navbar-nav .navbar-nav--profile-info
        display: flex
        justify-content: flex-end
        align-items: center
        margin-bottom: 8px
    +media-breakpoint-down(sm)
        padding-bottom: 0px
        height: unset
        position: relative
        .navbar-nav .navbar-nav--profile-info
            flex-wrap: wrap
            justify-content: flex-start
            margin-bottom: 20px

    &-form
        display: inline-block
    &-icon
        +icon-correct(1px)
        line-height: 1
        font-size: 16px
    .btn
        padding: 0 0.25rem
        background: none
        color: $black
        &:hover
            color: $dark-blue
            text-decoration: none
    .btn-profile
        color: $black
        padding: 0
        text-decoration: none
        outline: none
        line-height: 1.25rem
        border-radius: 0
        background: none
        span
            vertical-align: middle
        i
            +icon-correct(3px)
        padding-right: 0.5rem
        border:
            top: 0
            right: 1px solid #DADAE0
            bottom: 0
            left: 0
    span.btn-profile
        padding-right: 0
        border:
            top: 0
            right: 0
            bottom: 0
            left: 0
        cursor: default
        color: $black
        &:hover
            color: $black
    &-dropdown
        display: inline-block

    .link-home
        color: $gray-dark
    &-link
        line-height: 3rem
        padding: 0 1rem
        display: inline-block
        color: $black
        border-radius: 1px 1px 0 0
        &.active
            background-color: $gray
            color: $black

.profile-image
    +size(24px)
    display: inline-block
    box-shadow: $shadow-diffuse
    background:
        color: white
    margin-right: 5px
