.box
    &-action
        +make-row($gutter: 0)
        margin-bottom: $grid-gutter-width/2
        &-buttons
            +make-col(4)
            background: $gray-light
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            padding: 0.5rem
            .btn + .btn
                margin-top: 1rem
            +media-breakpoint-down(sm)
                +make-col(12)
        &-content
            +make-col(8)
            +media-breakpoint-down(sm)
                +make-col(12)      
        .card
            margin-bottom: 0
    &-plan
        border: 1px solid $gray-light
        padding: 1rem 1rem 0 1rem
        position: relative
        margin-bottom: 1rem
        &.disabled
            opacity: 0.5
            &:hover
                opacity: 1
        &-title
            font-size: 1.25rem
            margin-bottom: 1rem
        &-buttons
            margin-bottom: 1rem
            +media-breakpoint-up(md)
                +position(absolute, 1rem 1rem null null)
            form
                display: inline-block
                + form
                    margin-left: 0.5rem
    &-read
        margin: 2rem
        padding: 2rem
        border: 1px solid $gray
        background-color: white
        max-height: 40rem
        overflow: auto
    &-image
        padding: 1rem
        border: 1px solid $gray
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 1rem

        img
            max-width: 100%
        .col-md-3 &
            height: 100%
    &-date
        padding: 1rem 3rem 1rem 1rem
        position: relative
        border: 1px solid $gray
        .btn-delete-date
            position: absolute
            top: 0
            right: 0
        &.error
            [data-selector='day'], [data-selector='month'], [data-selector='year'], [data-selector='hour'], [data-selector='minute']
                border-color: $red

.dot-success:before
    font-family: 'Material Icons'
    font-size: 9px
    text-align: center
    line-height: 15px
    content: "check"
    background-color: $green
    +size(15px)
    display: inline-block
    border-radius: 50%
    color: white

.dot-error:before
    font-family: 'Material Icons'
    font-size: 9px
    text-align: center
    line-height: 15px
    content: "clear"
    background-color: $red
    +size(15px)
    display: inline-block
    border-radius: 50%
    color: white