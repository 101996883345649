=btn-icon($color: $brand-primary, $icon: "error", $position: before )
    position: relative
    border-color: transparent
    background:
        color: $color
    color: white
    overflow: hidden
    @if $position == "before"
        padding-left: 3rem
        &:before
            +material-icons(16px)
            +position(absolute, 0 null 0 0)
            content: $icon
            padding: 0.5rem
            background:
                color: transparentize($black, 0.93)
    @else if $position == "after"
        padding-right: 3rem
        &:after
            +material-icons(16px)
            +position(absolute, 0 0 0 null)
            content: $icon
            padding: 0.5rem
            background:
                color: transparentize($black, 0.93)
    &:hover
        color: white
        background:
            color: darken($color, 5%)
        &:before
            background:
                color: darken($color, 5%)

.btn
    &[type="button"]
        appearance: none
    &-save
        background-color: $brand-primary
        color: white
    &-add
        +btn-icon($brand-success, "add")
        &-inline
            background: none
            padding: 0
            color: $blue
            &:before
                +material-icons(16px)
                content: "add"
                vertical-align: baseline
                color: $blue
    &-send
        +btn-icon($brand-success, "send")
    &-edit
        +btn-icon($brand-success, "create")
        &-inline
            background: none
            padding: 0
            &:before
                +material-icons(16px)
                content: "create"
                vertical-align: baseline
                color: $black

    &-delete
         &-inline
            background: none
            padding: 0
            &:before
                +material-icons(16px)
                content: "delete"
                vertical-align: baseline
                color: #d9534f

    &-copy
        +btn-icon($brand-primary, "content_copy")
        margin-bottom: 1rem
    &-check
        +btn-icon($brand-success, "check")
    &-upload
        +btn-icon($brand-primary, "file_upload")
    &-download
        +btn-icon($brand-primary, "file_download")
    &-reject
        +btn-icon($brand-danger, "clear")
    &-download
        +btn-icon($brand-primary, "file_download")
        + .btn
            margin-top: 1rem
    &-next
        +btn-icon($brand-primary, "chevron_right", after)
    &-prev
        +btn-icon($brand-primary, "chevron_left")
    &-expand
        +btn-icon($brand-primary, "keyboard_arrow_down", after)
        + .collapse
            margin-top: 1rem
    &-postpone
        +btn-icon($brand-primary, "cached")
    &-rating
        +btn-icon(#F7CB50, "star")
    &-readmore
        padding: 0
        vertical-align: inherit
        background: none
        color: $brand-primary
    &-undo
        padding: 0
        background: none
        color: $brand-primary
        vertical-align: inherit
    &-remove
        color: $brand-danger
    &-blue
      color: $blue
    &-calendar
        background: none
        padding:
            left: 0
            right: 0
        font-size: 1.25em
    &-toggle
        background: transparent
        padding: 0
        line-height: 0
        i
            +material-icons(16px)
            color: $gray-dark
    &-cancel
        color: $gray-dark
        background: none
    &-reset
        font-size: 0.75rem
        color: $gray-dark
        cursor: pointer
    &-invoice
        position: relative
        border-color: transparent
        background:
            color: $green
        color: white
        overflow: hidden
        padding-left: 3rem
        &:hover
            color: white
            background:
                color: darken($green, 5%)
        &:before
            background:
                color: darken($green, 5%)
        &:before
            +position(absolute, 0 null 0 0)
            font-family: lex
            content: "\37"
            padding: 0.5rem
            background:
                color: transparentize($black, 0.93)
            margin-bottom: 1rem
            height: 100%
    &[disabled]
        background-color: darken($gray, 5%)

    &-nopadding
        padding: 0
.btn:focus:active,
.btn:focus
    outline: none

.dropdown-toggle
    padding: 0 0.5rem!important
    line-height: 22px
    vertical-align: middle

.form-btn
    width: 38px

.btn.btn-warning
    margin-right: 4px

.btn.btn-icon
    position: relative
    width: 30px
    height: 30px
    padding: 0
    i
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50% , -50%)
        font-size: 12px
.btn.btn-text
    border: none
    background: none
    padding: 0
    color: #A3A7C3
    &.success
        color: #64a71b
    &.danger 
        color: #d9534f
    