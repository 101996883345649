.chat-container
    max-height: 30rem
    overflow: auto
    > div:first-of-type
        margin-top: 1rem
    .message-content
        background-color: $gray-light
    .card-footer &
        .message-content
            background-color: white
.message
    &-box
        position: relative
        +make-row($gutter: 0)
        margin-bottom: 1rem
        padding-right: 2rem
        .btn-link
            padding: 0
            font-weight: 300
            vertical-align: inherit
            margin-bottom: 0
    &-avatar
        +make-col-ready($gutter:0)
        +size(30px)
        border-radius: 50%
        overflow: hidden
        background-color: $gray
        display: inline-flex
        justify-content: center
        align-items: center
        vertical-align: middle
        img
            +size(100%)
            &.avatar-placeholder
                +size(75%)
        &.message-avatar-placeholder
            border:  2px solid #EAEBF2
        + .message-container
            margin-left: 0.375rem
    &-content
        padding: 0.75rem
        width: 100%
        border-radius: 1px
        p
            font-weight: 300
            line-height: 2
        p:last-of-type
            margin: 0
        &:hover .message-actions
            +opacity-transition("in")
    &-container
        +make-col-ready($gutter:0)
        width: calc(100% - 2.5rem - 0.375rem)
        + .message-avatar
            margin-left: 0.375rem
    &-datetime
        +make-col(12)
        text-align: right
        font-size: 0.8333em
        font-weight: 300
    &-deleted
        font-weight: 300
        font-size: 0.8333em
        margin-bottom: 1rem
        .btn-link
            padding: 0
            font-weight: 300
            vertical-align: inherit
            font-size: 1rem
            +opacity-transition("out")
            color: #28386C
            i
                +icon-correct()
        &:hover
            .btn-link
                +opacity-transition("in")
                text-decoration: none
    &-actions
        +position(absolute, 0 0 null null)
        transform: translate(0,-50%)
        background-color: #28386C
        border-radius: 22px
        padding: 0 0.5rem
        +opacity-transition("out")
        .btn-link
            color: white
            border: 0
            padding: 0.125rem 0.25rem
            line-height: 1.5
            i
                vertical-align: middle
                +icon-correct(1px)
                font-size: 1rem
                margin: 0
                line-height: 1.5
            &:hover
                text-decoration: none
                opacity: 0.9
    &-add
        padding-top: 1rem
        text-align: right
        textarea
            border: 1px solid $gray
            border-radius: 1px
            overflow: auto
            &::placeholder
                font-weight: 300
                color: $gray-dark
            + .btn
                @media screen and (max-width: 1024px)
                    background: $brand-primary
                    padding: 0.5rem 1rem
                    color: white
                    margin-top: 1rem
                    font-size: 1rem
                    font-style: normal
                &:hover
                    text-decoration: none

.dashboard-sidebar .message-box
    padding-right: 0