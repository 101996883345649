.tag
    border-radius: $border-radius*3
    position: relative
    white-space: normal
    margin: 2px 1px
    &-default
        font-weight: 400
        background-color: $gray

.tags-table .tag
    text-align: left