.activity
    border-left: 1px solid $gray
    padding:
        right: 1rem
        left: 2rem
    margin-left: 1.25rem
    &-content
        margin-bottom: 1rem
        padding-bottom: 1rem
        border-bottom: 1px solid $gray
        display: inline-block
        vertical-align: middle
        line-height: 2.5rem
        min-width: 66.66%
        color: $black
        font-weight: 400
        width: 100%
    &.message
        position: relative
        &:before
            +size(2.5rem)
            font-family: 'Material Icons'
            content: "notifications"
            display: inline-block
            border: 1px solid $gray
            border-radius: 50%
            vertical-align: top
            transform: translate(-50%, 0)
            +position(absolute, 0 null null 0)
            background-color: white
            text-align: center
            line-height: 2.5rem
            color: $gray-dark
    &-name
        color: $brand-primary
    &-date
        color: transparentize($black, 0.6)
        font-weight: 300
        margin-left: 3px
    &-message
        display: block
        background: $gray-light
        border-left: 4px solid $brand-primary
        padding: .5rem 1rem 
        line-height: 1.5
        width: 100%
    &:last-of-type
        border: none
    &-add
        textarea
            margin-bottom: 1rem
    &-container-scrollable
        height: 150px
        overflow-y: auto
    &-actions
        form
            display: inline-block
    &.note-upload.message,
    &.note-upload.file
        .activity-name
            line-height: 1.4
            display: flex
            flex-direction: column
            .activity-date
                margin-bottom: 12px
                margin-left: 0

    &.file 
        .file-container a
            display: flex
            align-items: center
        .file-img
            width: unset!important
            background: none
            height: 20px
            margin-right: 2px
