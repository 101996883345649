.aanvraag-list {

    li.accordion-list{
        padding: 0px;
        margin: 0 0 20px;

        .accordion-list-header {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 8px;
            .material-icons {
                font-size: 14px;
                padding-left: 3px;
            }
            .aanvraag-list-naam {
                flex-grow: 1;
                margin-bottom: 0;
                .alert.alert-info.text-only{
                    padding: 0;
                    margin: 0;
                    background: none!important;
                    border: none!important;
                }
            }
            .aanvraag-list-action{
                position: unset;
                &.close {
                    color:#d9534f;
                    opacity: 1;
                }
            }
        }
        &.closed .aanvraag-list-item{
            cursor: pointer;
            &:hover{
                background-color: #d9edf7;
                border-color: #bcdff1;
            }
        }
        .aanvraag-list-item{
            background-color: $gray-light;
            border: 1px solid #EAEBF2;
            border-radius: 4px;
            padding: 1rem 1.5rem;
           
        }

        // }
        // .aanvraag-list-item{
        //     background-color: $gray-light;
        //     padding: 1rem 1.5rem;
        //     border: 1px solid #EAEBF2;
        //     border-radius: 4px;
        //     cursor: pointer;
        //     &:hover{
        //         background-color: #d9edf7;
        //         border-color: #bcdff1;
        //     }
        // }



        // &.closed .aanvraag-list-item{
        //     display: flex;
        //     align-items: center;
        //     flex-direction: row;

        //     .aanvraag-list-action {
        //         position: unset;
        //         flex-grow: 0;
        //     }
        // }
    }
}
        