.w-100{
    width: 100%;
}
.spacer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    &.wrap{
        flex-wrap: wrap;
    }
    
}

.stacked {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
}

.stacked,
.spacer {
    &-null {
        gap: 0px;
    }

    &-sm {
        gap: 0.5rem;
    }

    &-md {
        gap: 0.7rem;
    }
}

