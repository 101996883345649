.card-confirm
    > .row
        margin-bottom: 1rem
    .card-header

.confirm-title.row
    margin-bottom: 2rem !important
    padding-bottom: 1rem
    border-bottom: 1px solid $gray

.confirm-item
    margin-bottom: 2rem

    .cousinproduct-list li span
        font-size: 1rem
        text-align: left
        color: $black
        i
            vertical-align: middle

.confirm-price
    font-size: 2rem
    line-height: 0.75
    color: $green
    > span:last-of-type
        margin-bottom: 1rem
        display: inline-block

.confirm-content
    border-bottom: 1px solid $gray
    margin-bottom: 2rem
    .btn-download
        white-space: normal

.cousinproduct-list 
    padding: 0
    list-style: none
    li span:not(.product-status),
    li a
        padding: 0.5rem 1rem
        border: 1px solid $gray
        display: inline-block
        i
            vertical-align: middle
            margin-right: 3px
            line-height: 1.5
            display: inline-block
            color: $black
    li a 
        cursor: pointer
        &:hover
            color: #0275d8!important
    li
        margin-bottom: 0.5rem
    .product
        display: flex
        padding: 0.5rem 1rem
        border: 1px solid $gray
    .product-status
        font-style: italic
    .product-description
        width: 100%
        p:last-of-type
            margin-bottom: 0
    .product-title
        span.tag
            padding: 0.25rem 0.75rem
            font-size: 0.83333rem
            border: none
            vertical-align: middle