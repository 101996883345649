.confirm-card {
    color: #1f212a;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid #eaebf2;
}

//This will go after Jake's changes to make the confirm button a tooltip
.confirm-header-wrapper {
    padding: 4px 0;
    display: flex;
    align-items: center;
    gap: 4px;
}
