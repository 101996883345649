.box-productsummary
    padding: 1rem 1rem 1rem 2rem
    background: white
    &-title
        margin-top: 1rem
        margin-bottom: 0.5rem
        color: #2A3775
        text-transform: uppercase
    .product-title
        font-size: 1.25rem
        font-weight: 400
        margin-bottom: 1rem
    .expert-title
        color: $gray-dark
        margin-bottom: 0.5rem
    .price-title
        color: $gray-dark
        margin-bottom: 0.5rem