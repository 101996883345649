.expert-box
    text-align: center
    border: 2px solid $gray-light
    margin-bottom: 2rem
    display: flex
    flex-direction: column
    justify-content: space-between
    flex: 0 0 33.33333%
    max-width: 33.33333%
    +media-breakpoint-down(lg)
        flex: 0 0 40%
        max-width: 40%
    +media-breakpoint-down(md)
        flex: 0 0 80%
        max-width: 80%
    &-content
        padding: 2rem
    &-image
        +size(4rem)
        border-radius: 50%
        background-color: $gray
        overflow: hidden
        border: 2px solid $gray
        display: inline-flex
        justify-content: center
        align-items: center
        margin-right: 0.5rem
        margin-bottom: 1rem
        vertical-align: middle
        &-sm
            +size(2.5rem)
        img
            +size(100%)
        .company-placeholder,
        .avatar-placeholder
            +size(75%)
    &-footer
        background: $green
        color: white
        padding: 0.5rem 2rem
        font-size: 1.25rem
    h2
        font-size: 1.5rem
    p
        line-height: 1.5rem
        font-weight: 300
        &:last-of-type
            margin-bottom: 0
    ul
        list-style: none
        padding: 0
        // margin: 0
    .btn
        display: block