.file
    &-container
        +make-row($gutter: 0)
        margin-bottom: 1rem
        &:hover
            .file-actions
                +opacity-transition("in")
    &-img
        position: relative
        background-color: $gray-light
        display: flex
        align-items: center
        justify-content: center
        +size(100px 80px)
        transition: background $global-transition
        overflow: hidden
        .file-small &
            +size(3rem 3rem)
        &:after
            +position(absolute, 50% null null 50%)
            transform: translate(-50%, -50%)
            display: block
            font-family: lex
            font-size: 2.5rem
            content: "E"
            color: white
            +opacity-transition("out")
        &.file-download:after
            content: "q"
        img
            max-width: 100%
            max-height: 100%
        &-container
            +make-col-ready(12)
            width: auto
            max-width: 100px
            max-height: 100px
            &:hover
                .file-img
                    background-color: darken($gray-light, 5%)
                    transition: opacity $global-transition, background $global-transition
                    &:after
                        +opacity-transition("in")
                    img
                        opacity: 0.7
                        transition: opacity $global-transition
    &-content
        +make-col-ready(12)
        width: calc(100% - 100px)
        font-weight: 300
        padding-left: 0.8333rem
    &-title
        font-weight: 400
        color: $black
        text-overflow: ellipsis
        overflow: hidden
    &-description
        overflow: hidden
        word-break: break-all
        font-style: italic
    &-actions
        &:not(.active)
            +opacity-transition("out")
        .btn-link
            padding: 0
            font-weight: 300
            + .btn-link
                margin-left: 0.5rem
        &-pending
            .btn-link
                padding: 0
                font-weight: 300
                + .btn-link
                    margin-left: 0.5rem

.no-files
    margin-bottom: 1rem

.drop-container
    border: 2px dashed $gray
    border-radius: 1px
    padding: 2.5rem 1rem
    text-align: center
    margin-bottom: 1rem
    &-button
        padding: 0
        font-weight: 300
        background: none
        border: none
    + .btn-link
        padding: 0
        font-weight: 300
        color: $black
        width: 100%
        text-align: right
        margin-botton: 1rem

.file-container-highlight
    margin-bottom: 1rem
    border: 1px solid #d0e9c6
    border-radius: 1px
    background-color: #dff0d8
    padding: 1rem
    .file-title
        font-weight: bold

.file-container-wrapper-compact
    .file-container
        border: 1px solid $gray
        border-radius: 1px
        background-color: $gray-light
        padding: 1rem

    .file-img
        +size(50px 40px)


.file-content.selectable
    cursor: pointer

.file-container
    border: 2px solid transparent
    border-radius: 4px
    padding: 4px

.file-container.selected
    border: 2px solid $blue
    background-color: lighten($blue, 30)

.file-bulk-buttons
    display: flex
    justify-content: space-between
    margin-bottom: 1rem

.link-bulk-download
    display: flex
    color: $blue