@-webkit-keyframes bounce {

    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }


    40% {
        -webkit-transform: translateY(-16px);
    }


    60% {
        -webkit-transform: translateY(-7px);
    }
}

@-moz-keyframes bounce {

    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
    }


    40% {
        -moz-transform: translateY(-16px);
    }


    60% {
        -moz-transform: translateY(-7px);
    }
}

@-o-keyframes bounce {

    0%, 20%, 50%, 80%, 100% {
        -o-transform: translateY(0);
    }


    40% {
        -o-transform: translateY(-16px);
    }


    60% {
        -o-transform: translateY(-7px);
    }
}

@keyframes bounce {

    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }


    40% {
        transform: translateY(-16px);
    }


    60% {
        transform: translateY(-7px);
    }
}

.bounce {
    animation-name: bounce;
}

// plugin specific styles

.taggle_list {
    border: 1px solid $gray;
    background-color: white;
    padding: 0.5rem
}

.taggle_input {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 300;
}

.taggle_list li:not(.ui-menu-item) {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 5px 10px;
    margin-right: 8px;
}

.taggle_list .taggle {
    @extend .tag;
    cursor: pointer;
    transition: all .3s;
    animation-duration: 1s;
    animation-fill-mode: both;
    border-radius: $border-radius*3;
    position: relative;
    white-space: normal;
    margin: 2px 1px;
    font-weight: 400;
    background-color: $gray;
    color: $black;
    text-align: left;
}

.taggle_list .taggle_hot {
    background: #cac8c4;
}

.taggle_list .taggle .close {
    font-size: 1.1rem;
    position: absolute;
    top: 5px;
    right: 3px;
    text-decoration: none;
    padding-left: 2px;
    padding-top: 3px;
    line-height: 0.5;
    color: $black;
    padding-bottom: 4px;
    display: none;
    border: 0;
    background: none;
    cursor: pointer;
}

.taggle_list .taggle:hover {
    padding-right: 15px;
    background: darken($gray, 5%);
    transition: all .3s;
}

.taggle_list .taggle:hover > .close {
    display: block;
}

.taggle_list .taggle .close:hover {
    color: $brand-primary;
}

.taggle_placeholder {
    position: absolute;
    color: darken($gray, 5%);
    transition: opacity, .25s;
    user-select: none;
    padding: 1rem;
    display: block;
}

.taggle_input {
    display: inline-block;
    padding: 8px;
    padding-left: 0;
    margin-top: -5px;
    background: none;
    width: 100%;
    max-width: 100%;
}

.taggle_sizer {
    padding: 0;
    margin: 0;
    position: absolute;
    top: -500px;
    z-index: -1;
    visibility: hidden;
}

// jQuery autocomplete example


.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
}

.ui-menu {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: block;
    outline: none;
}

.ui-widget-content {
    background: #fff;
    color: $brand-primary;
}

.ui-menu .ui-menu-item {
    margin: 0;
    padding: 0;
    width: 100%;
}

.ui-menu .ui-menu-item a {
    text-decoration: none;
    display: block;
    padding: 2px .4em;
    line-height: 1.5;
    min-height: 0;
    font-weight: normal;
    color: $gray;
    cursor: pointer;

    &:hover {
        color: #fff;
        background: $brand-primary;
    }
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    background: $brand-primary;
    color: #fff !important;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
    color: #fff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: 1px solid #aaaaaa;
    background: #ffffff;
    font-weight: normal;
    color: #212121;
}

.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.autocomplete {
    max-height: 200px;
    overflow: auto;
    position: absolute;
    top: 66px;
    background: white;
    width: 99.5%;
    left: 0.25%;
    z-index: 2;
}

.autocomplete ul li {
    display: block;
    padding: 6px 8px;
}

.autocomplete ul li.selected, .autocomplete ul li:hover {
    background: #ff6633;
    color: #fff;
    cursor: pointer;
}

.ui-autocomplete {
    max-height: 200px;
    overflow: auto;
    width: 99% !important;
    z-index: 10;
    background: white;
    border: 1px solid $gray;
}

.ui-menu-item{
    margin-top: 0!important;
    border-top: none!important;
}
.ui-menu-item-wrapper{
    color: black;
    padding: 0.5rem !important;
    border-bottom: 1px solid $gray;
    display: block;
    text-align: left;
    &.ui-state-active{
        border: none;
        cursor: pointer;
        background: $brand-primary;
        color: white;
    }
}
