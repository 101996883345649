.divider
    text-align: center
    border-bottom: 1px solid transparentize($gray-dark, 0.75)
    margin-bottom: 3rem
    h2
        color: $gray-dark
        text-transform: uppercase
        background-color: $gray
        padding: 0 1rem
        margin: 0
        display: inline-block
        font-size: 1rem
        line-height: 2
        transform: translateY(50%)
