.sp-replacer
    background-color: $gray-light
    border-color: $gray
    &:hover, &:focus
        border-color: $gray-dark
    &.sp-active
        border-color: $gray-dark
.sp-container
    border-color: #EAEBF2
    background-color: $gray-light

.sp-input
    background-color: white
    border-radius: 0
    border-style: solid
    border-color: $gray
    height: 27px

.sp-button-container
    margin-top: 1rem
    button
        @extend .btn
        @extend .btn-primary
        background-image: none
        text-shadow: none

.form-control .sp-replacer.sp-light
    width: 100%
    background: none
    border: none
    padding: 3px 0
    display: flex
    align-items: center
    justify-content: space-between