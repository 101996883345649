.news-list
    h2
        font-size: 1.25rem
    p
        font-weight: 300
        &:last-of-type
            margin-bottom: 0
    &-item
        margin: 1rem 0
        + .news-list-item
            margin-top: 1.5rem
    &-date
        font-size: 0.83333em
        font-weight: 300