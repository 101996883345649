=material-icons($size: 24px)
    font-family: 'Material Icons'
    font-weight: normal
    font-style: normal
    font-size: $size
    display: inline-block
    line-height: 1
    text-transform: none
    letter-spacing: normal
    word-wrap: normal
    white-space: nowrap
    direction: ltr
    // /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased
    // /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility
    // /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale
    // /* Support for IE. */
    font-feature-settings: 'liga'

.material-icons
    +material-icons
    vertical-align: middle
    .btn-sm &
        font-size: 1rem

.icon-info
    +material-icons($size: 20px)
    vertical-align: text-bottom
    cursor: pointer
    color: #0275d8
    font-family: 'Material Icons' !important
