.maincontent > div:not(.maincontent-sidebar-right)
    .card
        box-shadow: $shadow-diffuse
        &-header-button
            display: flex
            align-items: center
        &-header
            padding-bottom: 0
            text-transform: uppercase
            color: $gray-dark
            background: none
            border: 0
            margin-bottom: 0
            .pull-right
                line-height: 0
            &-dark
                background-color: $gray-light
                padding-bottom: 1rem
                input[type="text"]
                    height: 4rem
                    + .btn
                        padding: 0
                        color: $black
                        font-size: 0.8333em
                        font-weight: 300
                        font-style: italic
                        background: none
                        border: none
                        float: right
                        strong
                            font-weight: 400
                        &:hover
                            text-decoration: none
            &-action
                line-height: 1
                float: right
                .btn
                    background: none
                    border: none
                    padding: 0
                    font-weight: 300
                    line-height: 1.125
                    i
                        font-size: 16px
                        +icon-correct
        &-footer
            background-color: $gray-light
.card-block
    p img
        max-width: 100%
    p
        +break-word
    &.activity-add.activity-add--upload
        flex-direction: column
        form > div
            width: 100%
            margin-bottom: 12px

            .singlefile
                position: relative
                display: flex
                align-items: center



            .singlefile-img
                width: unset!important
                max-width: unset!important
                background: none
                height: 30px
                max-height: 30px
                margin-right: 2px
                padding-top: 0
            .singlefile-actions

                button
                    position: absolute
                    top: -7px
                    right: 4px
                    border: none
                    background: #d9534f
                    border-radius: 50%
                    height: 14px
                    width: 14px
                    padding: 0
                    opacity: 0
                    opacity: 1
                    height: 14px
                    width: 14px
                    padding: 0
                    font-size: 0
                    overflow: hidden
                    &::before
                        content: 'x'
                        font-size: 14px
                        color: white
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                        padding-bottom: 4px

    .box-date.box-date__select
        display: flex
        justify-content: space-between
        align-items: center
        >div
            display: flex
            align-items: center
            i
                line-height: 1px
                margin-right: 4px
        &.active
            border-color: #0275d8
            background: lighten(#0275d8, 56%)
            >div
                color: #0275d8

.card-read
    h1, h2, h3, h4
        word-break: break-word
    +media-breakpoint-down(sm)
        padding: 2rem!important
        max-height: 500rem
        overflow: auto
        border:
            bottom: 3rem solid white

.card-empty
    padding: 8px 12px
    font-size: 12px
    background: #F7F7FA
    text-align: center
    margin-bottom: 20px
    color: #A3A7C3

.card-sticky
    position: sticky
    top: 16px
    height: 100vh
    overflow-y: auto