.search-result
	padding: 1rem
	background-color: white
	border: 1px solid $gray
	margin-bottom: 1rem
	display: flex
	align-items: center
	&:hover, &:focus, &:active
		text-decoration: none
	h2
		color: $gray-dark
		font-size: 1rem
		font-weight: 300
	label
		margin: 0
		color: $black

.search-category
	// +size(2.5rem)
	// line-height: 2.5rem
	// background: $gray-light
	// text-align: center
	// border-radius: 50%
	color: $black


.search-content
	padding: 0 2rem
	color: $black
	border-right: 1px solid $gray-light

.search-contact
	padding: 0 2rem
	border-right: 1px solid $gray-light
	.user-list-item
		margin: 0
.search-action
	padding: 0 2rem
	text-align: right
	flex-grow: 1

.search-maincontent
	.input-search-container
		margin-bottom: 2rem