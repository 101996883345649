.producttype-list
    margin-bottom: 2rem
    .col
        flex-shrink: 0
        width: 350px
        margin-bottom: 1rem
    &-item
        +size(100%)
        border: 2px solid $gray-light
        padding: 2rem
        text-align: center
        margin: 0
        cursor: pointer
    input:checked + .producttype-list-item
        border-color: $brand-primary
        .btn
            background: $gray-light
            color: $gray-dark
            border-color: $gray-light
    &-image
    &-content
    h2
        font-size: 1.5rem
    p
        line-height: 1.5rem
        font-weight: 300
    ul
        list-style: none
        padding: 0
        // margin: 0
    .btn
        display: block