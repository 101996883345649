=icon-correct($correction: 1px)
    transform: translate(0, $correction)
    display: inline-block
    vertical-align: middle

.icon-toggle-filled
    color: $green

@font-face
    font-family: "lex"
    src: url("../fonts/lex.eot")
    src: url("../fonts/lex.eot?#iefix") format("embedded-opentype"), url("../fonts/lex.woff") format("woff"), url("../fonts/lex.ttf") format("truetype"), url("../fonts/lex.svg#lex") format("svg")
    font-weight: normal
    font-style: normal

[data-icon]:before
    font-family: "lex" !important
    content: attr(data-icon)
    font-style: normal !important
    font-weight: normal !important
    font-variant: normal !important
    text-transform: none !important
    speak: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

[class^="icon-"]:before,
[class*=" icon-"]:before
    font-family: "lex" !important
    font-style: normal !important
    font-weight: normal !important
    font-variant: normal !important
    text-transform: none !important
    speak: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.iconTruck
    width: 25px
    height: 17px
    background:
        image: url("../img/local_shippin.png")
        size: 75%
        repeat: no-repeat
        position: center

.iconTODOREMOVETHIS
    width: 25px
    height: 17px
    background:
        image: url("../img/local_shippin.png")
        size: 75%
        repeat: no-repeat
        position: center

.icon-ios-arrow-down:before
    content: "\63"

.icon-ios-arrow-left:before
    content: "\64"

.icon-ios-arrow-up:before
    content: "\66"

.icon-ios-arrow-right:before
    content: "\65"

.icon-ios-chatbubble-outline:before
    content: "\6d"

.icon-ios-chatbubble:before
    content: "\6e"

.icon-ios-search-strong:before
    content: "\72"

.icon-ios-search:before
    content: "\73"

.icon-ios-compose-outline:before
    content: "\76"

.icon-ios-bell-outline:before
    content: "\77"

.icon-ios-bell:before
    content: "\46"

.icon-ios-locked-outline:before
    content: "\4b"

.icon-log-out:before
    content: "\4e"

.icon-settings:before
    content: "\4f"

.icon-ios-upload-outline:before
    content: "\6f"

.icon-arrow-return-left:before
    content: "\44"

.icon-trash-b:before
    content: "\4d"

.icon-trash-a:before
    content: "\74"

.icon-reply:before
    content: "\4c"

.icon-arrow-expand:before
    content: "\45"

.icon-down-arrow:before
    content: "\53"

.icon-sort:before
    content: "\54"

.icon-up-arrow:before
    content: "\55"

.icon-toggle:before
    content: "\50"

.icon-toggle-filled:before
    content: "\51"

.icon-android-radio-button-off:before
    content: "\56"

.icon-android-radio-button-on:before
    content: "\57"

.icon-arrow-right-b:before
    content: "\58"

.icon-ios-flask-outline:before
    content: "\59"

.icon-star:before
    content: "\41"

.icon-star-half:before
    content: "\42"

.icon-star-empty:before
    content: "\7a"

.icon-aanvragen:before
    content: "\33"

.icon-abonnementen:before
    content: "\34"

.icon-dashboard:before
    content: "\35"

.icon-dossiers:before
    content: "\36"

.icon-facturen:before
    content: "\37"

.icon-gebruikers:before
    content: "\38"

.icon-nieuws:before
    content: "\39"

.icon-producten:before
    content: "\21"

.icon-tags:before
    content: "\23"

.icon-download:before
    content: "\61"

.icon-upload:before
    content: "\78"

.icon-reject:before
    content: "\6b"

.icon-check:before
    content: "\43"

.icon-edit:before
    content: "\75"

.icon-add:before
    content: "\62"

.icon-close:before
    content: "\67"

.icon-calendar:before
    content: "\68"

.icon-bell:before
    content: "\6a"

.icon-bell-slash:before
    content: "\6c"

.icon-unflag:before
    content: "\69"

.icon-flag:before
    content: "\79"

.icon-ratings:before
    content: "\70"

.icon-archive:before
    content: "\71"

.icon-widgets:before
    content: "\47"

.icon-combination
    i:last-of-type
        font-size: 9px
        position: absolute
        top: 12px
        left: 50%
        transform: translateX(-50%)

