.wizard
    background-color: white
    box-shadow: $shadow-diffuse
    +media-breakpoint-down(sm)
        margin-bottom: 1rem
    &-step
        font-size: 1.25em
        line-height: 2.5rem
        font-weight: 300
        color: transparentize($gray-dark, 0.6)
        background-color: white
        padding:
            top: 1rem
            right: 1rem
            bottom: 1rem
            left: 2rem
        border-bottom: 1px solid $gray
        +media-breakpoint-down(lg)
            font-size: 1rem
            line-height: 1.5rem
        &:not(.checked, .active)
            +media-breakpoint-down(sm)
                display: none
        &.checked
            color: $green
            &:after
                +material-icons
                +size(2.5rem)
                line-height: 2.5rem
                border: 1px solid transparentize($gray-dark, 0.6)
                border-radius: 50%
                color: $gray
                margin-right: 1rem
                content: "check"
                border-color: $green
                color: white
                background-color: $green
                text-align: center
                float: right
                +media-breakpoint-down(lg)
                    +size(1.5rem)
                    line-height: 1.5rem
                    font-size: 14px

        &.active
            background-color: $gray-light
            color: $black
        
        &-section
            color: $black

