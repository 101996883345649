.singlefile
	width: calc(2rem + 150px)
	border: 1px solid $gray
	padding: 0 1rem

	.singlefile-img
		padding-top: 1rem
		max-width: 150px
		max-height: 150px

	.singlefile-block
		padding: 1rem 0
		text-overflow: ellipsis
		overflow: hidden

	.singlefile-actions
		margin: 0 -1rem
		padding: 1rem
		border-top: 1px solid $gray
