html
    height: 100%
  
body
    min-height: 100%
    background:
        color: $gray

.align
    &-justify
        justify-content: space-between
    &-right
        justify-content: flex-end
    &-middle
        align-items: center
    &-center
        justify-content: center

.col
    +make-col-ready()
    width: auto

dl
    +make-row($gutter: 0)
    line-height: 2
    &.dl-full
        dt
            +make-col-ready(12)
            width: 9.5em
            +make-col(12)
            + dd
                +make-col(12)
                margin-bottom: 0.5rem

    &:not(.dl-full)
        dt
            +make-col-ready(12)
            width: 9.5em
            &:not(.dt-full)
                padding-right: 0.5rem
            &.dt-full
                +make-col(12)
                + dd
                    +make-col(12)

dd
    +make-col-ready(12)
    width: calc(100% - 9.5em)
    font-weight: 300
    margin: 0
    min-width: 130px
    word-break: break-word

.collapsible-text
    p:last-of-type
        display: inline

// .field-description
//     padding: 0.5rem 1rem
//     border: 1px solid $gray-light
//     border-radius: 1px

.text-center
    text-align: center

.tag
    line-height: 2

// temporary fix
// *
//     +break-word
.nogrow
    flex-grow: 0 !important

.mt-0
    margin-top: 0!important

.mb-0
    margin-bottom: 0!important

.justify-content-between
    justify-content: space-between

.justify-content-center
    justify-content: center