=opacity-transition($direction)
    @if $direction == "out"
        opacity: 0
        visibility: hidden
        transition: opacity $global-transition
    @if $direction == "in"
        opacity: 1
        visibility: visible
        transition: opacity $global-transition

