=loginform($h1-color: $dark-blue, $primary-color: #36B4A6, $url: "../img/lex-bg.jpg")
    h1
        color: $h1-color
    .loginform-left
        background-image: url($url)
    button[type="submit"]
        background-color: $primary-color
        border-color: $primary-color
        &:hover, &:focus, &:active
            background-color: darken($primary-color, 5%)
            border-color: darken($primary-color, 5%)
    a
        color: $primary-color
        &:hover
            color: darken($primary-color, 5%)