// /*
//   The master.scss file imports all components that we use in this project.
//   This means we import Bootstrap in here and we import our custom components.
//   Bootstrap is imported via the node_modules folder.
//   We use includePaths inside the webpack.config.js which makes it possible to directly import bootstrap files inside the master.scss.
//   Custom components can do two things. They overwrite the prebuild bootstrap components OR they create a new component.
// */
// Import libraries
@import "settings"

// -- IMPORT BOOTSTRAP --
@import "bootstrap/scss/bootstrap-flex.scss"

// External libraries
@import "bourbon/app/assets/stylesheets/_bourbon.scss"
$fa-font-path: "font-awesome/fonts"
@import font-awesome
@import "toastr/toastr.scss"
@import chosen.scss
@import "spectrum-colorpicker/spectrum.css"
@import "react-big-calendar/lib/sass/styles.scss"

// Import settings and variables

// Import patterns which only contain mixins and extends
@import patterns/font-rendering
@import patterns/material-icons
@import patterns/transitions
@import patterns/animate
@import patterns/icons
@import patterns/buttons
@import patterns/button-group
@import patterns/button-tab
@import patterns/confirm-button

@import patterns/labels
@import patterns/form-elements
@import patterns/form-set

@import patterns/wordbreak
@import patterns/rating
@import patterns/datepicker
@import patterns/login
@import patterns/tags
@import patterns/avatar-container
@import patterns/grid
@import patterns/checklist

// Import sections which represent separate places in the application
@import sections/misc
@import sections/navbar
@import sections/page
@import sections/modal
@import sections/modal-animation
@import sections/tables
@import sections/box
@import sections/box-user
@import sections/datepicker

@import sections/login
@import sections/dashboard
@import sections/dashboard-timeline
@import sections/news-list
@import sections/sidebar
@import sections/maincontent
@import sections/maincontent-sidebar-right
@import sections/searchexpert
@import sections/chat
@import sections/activity
@import sections/wizard
@import sections/wizard-horz

@import sections/divider
@import sections/tablesorter
@import sections/card
@import sections/product
@import sections/expert-results-list
@import sections/tinymce
@import sections/rating
@import sections/leave
@import sections/confirm
@import sections/staffel-inputs
@import sections/question-list

@import sections/search
@import sections/singlefile

// lists
@import sections/file-list
@import sections/user-list
@import sections/expert-list
@import sections/expert-list-mod.scss
@import sections/product-list
@import sections/aanvraag-list
@import sections/aanvraag-list-accordion
@import sections/checkbox-list
@import sections/tag-list
@import sections/productype-list

@import sections/expert-box
@import sections/price-box
@import sections/product-box
@import sections/icon-box
@import sections/card-confirm
@import sections/nav-tabs
@import sections/modal-map
@import sections/box-productsummary

@import sections/taggle

@import sections/box-code
@import sections/spectrum

// lists
@import sections/calendar/calendar
@import sections/calendar/calendar-content
@import sections/calendar/calendar-view-mode



// Below are the new CSS imports from a folder called "shared." 
// This approach has been chosen because there are too many SCSS 
// files, and it's unclear where and how each one is used. New functions 
// and pages have been added. Since we intend not to use 
// styled-component styling and prefer sticking to SCSS, a new folder 
// has been created for the new CSS elements to maintain a clean and clear 
// styling library.

@import shared/card.scss
@import shared/btn.scss
@import shared/spacer.scss
@import shared/icon.scss
@import shared/validation-group.scss


// -- SHAME --
.metismenu li.active > a
	font-weight: bold

.viewing .inline-edit
	display: none
.editing .inline-value
	display: none

tr.editing, #pending-files tr
	background-color: cornsilk

.table-invoice .editing .input-group
	.input-group-addon
		flex-shrink: 0
		width: 2.5rem
	.form-control
		max-width: calc(100% - 2.5rem)

.form-control.error
	border-color: $red

.pre-line
	white-space: pre-line

// for fine-uploader
.qq-hide
	display: none

.qq-upload-list
	display: none

.fileupload.error .qq-upload-button-selector
	color: $red

.qq-upload-drop-area
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-color: transparentize(#333, 0.5)
	z-index: 1035

	&-description
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		color: white
		font-size: 3em
		text-align: center

.file-list
	img[src=""]
		display: none


// // aanvraag selector
// input.noshow
// 	position: absolute
// 	transform: translateX(-9999px)

// input:checked + .aanvraag-type-option
// 	background-color: #eee

// .aanvraag-type-option
// 	width: 100%
// 	text-align: center
// 	padding: 1rem
// 	border: 2px solid #333
// 	border-radius: 5px

// 	i.fa
// 		display: block
// 		font-size: 80px

// 	input
// 		display: none

// 	&:hover
// 		border-color: #666

.table-selfsorted tr
	transition: transform 0.5s ease-in-out

.tag-default
	color: black


.logo-container
	+ form
		margin-top: 1rem
	img
		max-width: 400px
		max-height: 100%
		width: 100%
	&-small
	&-medium
		background: white
		box-shadow: $shadow-diffuse
		padding: 0.5rem
		+size(60px)
		display: inline-block
		margin-right: 0.5rem
		vertical-align: middle
		img
			max-width: 100%
			max-height: 100%
	&-large

.profile-image

#doorbell
	&-background
		background: rgba(31,32,42,0.60)
	form
		border-radius: $border-radius
		box-shadow: $shadow-modal
		legend
			padding-bottom: 1rem
	&-powered-by
		color: $gray

.chosen-container
	max-width: 100%

.status-active
	padding: 0.25rem 0.5rem
	background-color: $brand-success
	color: white
	border-radius: $border-radius

.status-danger
	padding: 0.25rem 0.5rem
	background-color: $brand-danger
	color: white
	border-radius: $border-radius

.inline-block
	display: inline-block

.form-control-margin-right
	margin-right: 1rem

.form-check-input.error + .form-check-label + .form-check-error-label
	color: $red
	padding-left: 1.25rem
	display: block

.form-check-input + .form-check-label + .form-check-error-label
	display: none

.card-outline-info
	border-width: 1px

.text-right
	text-align: right

.row-md-reverse
	+media-breakpoint-down(md)
		flex-direction: column-reverse

.inexcluded-list
	padding: 0
	list-style: none
	i
		font-size: 1.5rem

.align-bottom
	align-self: flex-end

blockquote.quote
	border: 1px solid $gray
	padding: 1rem

a.alert
	display: block

.table-head-wrapper
	overflow-x: hidden
	overflow-y: hidden

.table-wrapper
	overflow-x: auto
	overflow-y: auto

.input-price
	.form-control
		max-width: 8rem

.mb1
	margin-bottom: 1rem

.properties
	label
		display: block
		font-weight: bold

	span
		display: block
		margin-bottom: 1rem

.vid-stamp
	display: flex
	align-items: center
	flex-direction: column
	padding: 1rem
	margin-top: 1rem
	justify-content: center
	max-width: 80%

	p
		margin: 0
		margin-top: 1rem

	&-success
		border: 1px solid $alert-success-border
		background-color: $alert-success-bg
		color: $alert-success-text

	&-error
		border: 1px solid $alert-danger-border
		background-color: $alert-danger-bg
		color: $alert-danger-text

.commissie-table
	tr
		td:first-of-type
			max-width: 200px
			width: 150px

		dl
			dt
				width: 300px
			dd
				width: calc(100% - 300px)

.space-between
	display: flex
	justify-content: space-between



.hide
	display: none

#aanvraag-loading-spinner
	position: fixed
	z-index: 99999
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-color: #fff
	opacity: 0.5

	.loading-message
		margin: 0 auto
		font-size: 24pt
		text-align: center
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: 100%

@media only screen and (max-width: 912px)
	.desktop-text
		display: none

@media only screen and (min-width: 912px)
	.mobile-text
		display: none

// Used by the `LoadingWrapper` component to indicate that the contents of this div are loading
.loading-glimmer
	animation: loading-glimmer 1.5s infinite

@keyframes loading-glimmer
	0%
		opacity: 1
	50%
		opacity: 0.5
	100%
		opacity: 1

#questionnaire-app
	position: relative

.center-loading
	display: block
	position: absolute
	top: 50%
	left: 50%
	transform: translateX(-50%)
	background-color: white
	border-radius: 4px
	box-shadow: 0 0 20px 0 rgba(98,101,119,0.1)
	padding: 1rem 2rem
	text-align: center

	p
		margin: 0
