.rbc-calendar{
	.rbc-btn-group{

        //---BUTTONS---
        button{
                font-size: 1rem;

                &:first-child:not(:last-child) {
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }
                &:last-child:not(:first-child) {
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                }
                &:focus, &:active{
                    outline:  none;
                }

            &.rbc-active{
                background: #0275d8;
                border-color: #0275d8;
                color: white;
                &:hover{
                    background: #01549b;
                    border-color: #01549b;  
                }
            }
        }
    }
}