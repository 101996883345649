.expert-list
    &-item
        margin-bottom: 2rem
        background-color: $gray-light
        display: flex
        // max-width: 680px
        flex-wrap: wrap
        h1
            font-size: 1.25rem
            line-height: 1
            display: inline-block
            vertical-align: middle
        h2
            color: $gray-dark
            font-size: 1rem
            font-weight: 300
            line-height: 1
            display: block
        p
            margin-bottom: 0
            font-size: 1rem
            font-weight: 300
        a
            font-size: 1rem
            font-weight: 300

        &.recommended
            outline: 2px solid $green

        &.chosen
            outline: 2px solid #0275d8
    
    &-box0
        padding: 1rem
        width: 100%
        flex-shrink: 0

    &-box1
        padding: 1rem
        width: 130px
        border-right: 1px solid $gray
        flex-shrink: 0
        +media-breakpoint-down(md)
            width: 50%
        +media-breakpoint-down(md)
            width: 100%
    &-box2
        width: 150px
        flex-shrink: 0
        +media-breakpoint-down(md)
            width: 50%
        +media-breakpoint-down(md)
            width: 100%
            
    &-box3
        padding: 1rem
        width: 250px
        flex-grow: 1
        +media-breakpoint-down(md)
            width: 50%
        +media-breakpoint-down(md)
            width: 100%
    &-box4
        padding: 1rem
        width: 150px
        border-left: 1px solid $gray
        flex-shrink: 0
        display: flex
        flex-direction: column
        justify-content: space-between
        +media-breakpoint-down(md)
            width: 50%
        +media-breakpoint-down(md)
            width: 100%
    &-box5
        width: 100%
        flex-schrink: 0
        flex-grow: 0
        text-align: center
        border-top: 1px solid $gray
        padding: 1rem
        a
            width: 100%

    &-logo
        box-shadow: $shadow-diffuse
        background-color: white
        padding: 0.5rem
        height: 6rem
        width: 100%
        vertical-align: top
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 1rem
    &-users
        margin: 0 -2px
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
        &-user
            margin: 2px
            display: inline-block
            img
                border-radius: 50%
                width: 35px
                height: 35px
                border: 2px solid white
            &.primaryexpert
                // width: 100%
                text-align: center
                img
                    width: 45px
                    height: 45px
                    border-color: $green
        &-more
            color: $gray-dark     
            text-align: left
            display: block
            font-weight: 300
    &-progressbar
        border:
            top: 1px solid $gray
            bottom: 1px solid $gray
            right: 1px solid $gray
        +size(100%)
        padding: 1rem
        +media-breakpoint-down(md)
            border-right: 0
        h2
            font-size: 0.75rem
        +media-breakpoint-up(md)
            border-top: 0
            h2
                font-size: 1rem
            .progress
                color: $green
                height: 0.5rem
                margin: 11px 0 0 0
    &-rating
        display: block
        border:
            top: 1px solid $gray
            bottom: 1px solid $gray
            right: 1px solid $gray
        +size(100%)
        padding: 1rem
        +media-breakpoint-down(md)
            border-right: 0
        .rating:before
            font-size: 1rem
            letter-spacing: -2px
            vertical-align: middle
        .content
            line-height: 1
            display: inline-block
            
        h2
            font-size: 0.75rem
        +media-breakpoint-up(md)
            border-top: 0
            h2
                font-size: 1rem
        a
            font-size: 0.875rem
            margin-top: 5px
            display: block
        .material-icons
            font-size: 1rem
            color: $dark-blue
            margin-left: 0.25rem
            vertical-align: middle
            opacity: 0
            visibility: hidden
            transform: translateY(-1px)
        &:link:hover
            .material-icons
                visibility: visible
                opacity: 1
            background-color: $gray


    &-distance
        border:
            top: 1px solid $gray
            bottom: 1px solid $gray
            right: 1px solid $gray
        +size(100%)
        padding: 1rem
        margin-bottom: 1rem
        +media-breakpoint-down(md)
            border-right: 0
        .content
            line-height: 1
            display: inline-block
           
        h2
            font-size: 0.75rem
        +media-breakpoint-up(md)
            border-top: 0
            h2
                font-size: 1rem
    &-description
        +make-col-ready()
        +make-col(12)
        overflow: hidden
        height: 11em
        overflow: hidden
        text-overflow: ellipsis
        text-align: left
        // width: calc(100% - 25rem)
        // max-width: none
        .content
            padding: 1rem 0
        .expert-name
            display: block
            .link-detail
                +opacity-transition("out")
            &:hover .link-detail
                font-weight: 300
                color: $brand-primary
                font-size: 0.875em
                text-decoration: none
                +opacity-transition("in")

        &.expanded
            height: auto
            .expert-list-gradient
                display: none
            .expert-list-more-info
                display: block
        .expert-list-gradient
            +position(absolute, null 0 0 0)
            text-align: center
            z-index: 1
            &:before
                +position(absolute, 0 0 null 0)
                transform: translate(0, -100%)
                background-image: linear-gradient(to bottom, rgba(247,247,250,0) 0%, rgba(247,247,250,0.92) 73%, rgba(247,247,250,1) 79%)
                +size(50px)
                content: ""
                display: block
            a
                line-height: 2.5rem
                display: block
                text-align: center
                background-color: $gray-light
                &:hover
                    background-color: $gray
    &-select
        width: 100%
        padding-bottom: 1rem
        +media-breakpoint-up(md)
            padding: 2rem 0 0
            text-align: right
        .btn-select
            color: $brand-primary
            border-color: $brand-primary
            white-space: normal
            &:hover
                color: darken($brand-primary, 10%)

    &-price
        color: $green
        border-radius: 1px
        width: 100%
        font-size: 1.5rem
        line-height: 50px
        +media-breakpoint-up(md)
            // padding: 2rem 0
            text-align: right
    &-price-detail
        color: $gray-dark
        font-size: 1rem
        font-weight: 300
        line-height: 1
    &-certificate
        +make-row($gutter: 0)
        h2
            width: 100%
        .certificate
            +make-col-ready(12)
            width: 50px
            height: 50px
            display: flex
            justify-content: center
            align-items: center
            padding: 0.5rem
            border: 1px solid $gray-light
            background: white
            + certificate
                margin-left: 0.5rem
                vertical-align: middle
            img
                +size(100%)

    &-more-info
        display: none
        margin-top: 1rem



    input[type="radio"]:checked + .btn-select,
    input[type="checkbox"]:checked + .btn-select
        border-color: $brand-primary
        background-color: $brand-primary
        color: white


.selected-experts,
.selected-opdrachtgevers
    padding: 1rem
    border: 1px solid $gray
    background-color: $gray-light   

.btn-less-info
    text-align: center
    margin-top: 1rem
    display: block

.tooltip-image
    display: block
    margin: 0 auto

.selected-experts,
.selected-opdrachtgevers
    .user-list-item
        display: flex
        min-width: 0
        padding-right: 0
