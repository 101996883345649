=rating($number)
    font-size: 1.25rem
    &:before
        +material-icons
        color: #F7CB50
        vertical-align: middle
        letter-spacing: -3px
        @if $number == 0
            content: "star_border star_border star_border star_border star_border"
        @if $number == 0.5
            content: "star_half star_border star_border star_border star_border"
        @if $number == 1
            content: "star star_border star_border star_border star_border"
        @if $number == 1.5
            content: "star star_half star_border star_border star_border"
        @if $number == 2
            content: "star star star_border star_border star_border"
        @if $number == 2.5
            content: "star star star_half star_border star_border"
        @if $number == 3
            content: "star star star star_border star_border"
        @if $number == 3.5
            content: "star star star star_halfstar_border"
        @if $number == 4
            content: "star star star star star_border"
        @if $number == 4.5
            content: "star star star star star_half"
        @if $number == 5
            content: "star star star star star"
    .table &
        display: inline-block
        vertical-align: middle
    .card-header &,
    .rating-item-header &
        float: right
    > span
        vertical-align: middle
        font-size: 0.875rem
        font-style: normal
        line-height: 1
        color: $gray-dark
        font-weight: 300

.rating
    display: block
    &-0
        +rating(0)
    &-5
        +rating(0.5)
    &-10
        +rating(1)
    &-15
        +rating(1.5)
    &-20
        +rating(2)
    &-25
        +rating(2.5)
    &-30
        +rating(3)
    &-35
        +rating(3.5)
    &-40
        +rating(4)
    &-45
        +rating(4.5)
    &-50
        +rating(5)