#map-modal
    .modal-dialog
        +media-breakpoint-down(xs)
            width: 100%
    &:before
        +media-breakpoint-down(xs)
            left: auto
            right: 0
            color: $gray
    .modal-header
        +media-breakpoint-down(xs)
            // padding: 1rem 0.5rem
            // display: none
    .modal-body 
        +media-breakpoint-down(xs)
            // padding-left: 0
            // padding-right: 0
            // padding-top: 0.5rem
            width: calc(100% - 60px)
            padding: 1rem 0.5rem
            p
                // display: none
                // padding: 0 2rem
            #postcode-map
                max-height: calc(50vh)
            .form-group
                // padding: 0 1rem
    .modal-footer
        +media-breakpoint-down(xs)
            padding: 0.5rem 1rem
            // position: fixed
            // bottom: 0
            // width: 100%
            // background: white