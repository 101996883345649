.react-card.card {
    .card-header-button {
        &:has(.card-header + button) {
            display: flex;
            align-items: center;
            gap: 0.7rem;
            padding: 1.5rem 1.5rem 0;
            .card-header {
                padding:0;
            }
        }
    }
     .card-block .card-header{
        padding: 0;
    }
}